import React, { useState, useEffect } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { auth, database } from '../firebase/firebaseConfig';
import { ref, get,set } from 'firebase/database';
import Swal from 'sweetalert2';
import './PostLoginModal.css'; // Ajuste o caminho

const PostLoginPage = () => {
  const [dob, setDob] = useState('');
  const [nickname, setNickname] = useState('');
  const [accountName, setAccountName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setAccountName(user.displayName || '');
      const userRef = ref(database, `usuarios/${user.uid}`);
      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {
          Swal.fire({
            title: 'Dados já registrados',
            text: 'Você já completou seu perfil. Redirecionando para o dashboard...',
            icon: 'info',
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
            }
          }).then(() => {
            navigate('/dashboard');
          });
        }
      }).catch(error => {
        console.error('Erro ao acessar dados do usuário:', error);
        // Tratar o erro conforme necessário
      });
    } else {
      navigate('/');
    }
  }, [navigate]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
  
    if (!user) return;
  
    let shouldProceed = true;
  
    if (!nickname.trim()) {
      Swal.fire('Apelido Obrigatório', 'Por favor, preencha o campo do apelido.', 'warning');
      return;
    }
  
    if (accountName.includes(' ')) {
      Swal.fire('Nome da Conta Inválido', 'O nome da conta não pode conter espaços.', 'error');
      return;
    }
  
    const usersRef = ref(database, 'usuarios');
    await get(usersRef).then((snapshot) => {
      if (snapshot.exists()) {
        const users = snapshot.val();
        const accountNames = Object.values(users).map(user => user.nome);
  
        if (accountNames.includes(accountName)) {
          Swal.fire('Nome da Conta Indisponível', 'Este nome de conta já está em uso. Por favor, escolha outro.', 'error');
          shouldProceed = false;
        }
      }
    });
  
    if (!shouldProceed) return;
  
    const apelidoRef = ref(database, `apelidos/${nickname}`);
    await get(apelidoRef).then((apelidoSnapshot) => {
      if (apelidoSnapshot.exists()) {
        Swal.fire('Apelido Indisponível', 'Este apelido já está em uso. Por favor, escolha outro.', 'error');
        shouldProceed = false;
      }
    });
  
    if (!shouldProceed) return;
  
    await set(ref(database, `usuarios/${user.uid}`), {
      nome: accountName,
      apelido: nickname,
      email: user.email
    });
  
    await set(apelidoRef, user.uid);
  
    Swal.fire('Perfil Atualizado', 'Suas informações foram salvas com sucesso!', 'success').then(() => {
      navigate('/dashboard');
    });
  };
  
  

  return (
    <div className="post-login-container">
      <h1>Complete seu Perfil</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Nome da Conta:</label>
          <input type="text" value={accountName} onChange={e => setAccountName(e.target.value)} required />
        </div>
      
        <div className="form-group">
          <label>Apelido:</label>
          <input type="text" value={nickname} onChange={e => setNickname(e.target.value)} required />
        </div>
        <button type="submit" className="btn btn-salva">Salvar e Continuar</button>
        <Link to="/" className="back-to-login-link">Voltar para a tela inicial</Link>
      </form>
    </div>
  );
};

export default PostLoginPage;
