import React, { useState, useEffect, useRef } from 'react';
import { ref, onValue, get,update } from 'firebase/database';
import { database } from '../firebase/firebaseConfig';
import LigaDetalhes from './LigaDetalhes';
import useIsMobile from '../hooks/useIsMobile';
import LigaDetalhesMobile from './LigaDetalhesMobile';
import Swal from 'sweetalert2'; // Importar a biblioteca Swal
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


const AtualizaPontosLiga = () => {
  const isMobile = useIsMobile();
  const [liga, setLiga] = useState({ nome: '', membros: [], id: null });
  const isMounted = useRef(true);
  const [modalAlteracaoShow, setModalAlteracaoShow] = useState(false);
  const [membroSelecionado, setMembroSelecionado] = useState('');
  const [senhaTemporaria, setSenhaTemporaria] = useState('');
  const [novaPosicao, setNovaPosicao] = useState('');
  const [modalShow, setModalShow] = useState(false);
  



 
  useEffect(() => {
    const ligaRef = ref(database, '/ligas/-Nu0obu4kFv5j9bR_Q1i');
    
    const unsubscribe = onValue(ligaRef, async (snapshot) => {
      if (isMounted.current) {
        const ligaData = snapshot.val();
        if (!ligaData) {
          setLiga({ nome: "Nenhuma liga encontrada" });
          return;
        }

        const membrosPromises = Object.keys(ligaData.usuarios || {}).map(async (userId) => {
          const usuarioRef = ref(database, `/usuarios/${userId}`);
          const userSnapshot = await get(usuarioRef);
          const userData = userSnapshot.val();
        
          return {
            id: userId,
            apelido: userData ? userData.apelido : 'Sem Apelido',
            pontos: ligaData.usuarios[userId].pontos,
            ultimaAtualizacao: userData.ultimaAtualizacao || 0, // Assumindo que este campo existe e é um timestamp
          };
        });
        
        const membros = await Promise.all(membrosPromises);
        
        // Ordenar membros por pontos (do maior para o menor) e pela última atualização em caso de empate
        membros.sort((a, b) => {
          if (b.pontos === a.pontos) {
            return a.ultimaAtualizacao - b.ultimaAtualizacao; // Prefere o membro que foi atualizado mais cedo
          }
          return b.pontos - a.pontos;
        });
        

        setLiga({
          id: '-Nu0obu4kFv5j9bR_Q1i',
          nome: ligaData.nome,
          membros: membros,
          premiacoes: ligaData.premiacoes || {}
        });
      }
    });

    return () => {
      unsubscribe();
      isMounted.current = true;
     
    };
  }, []);



  const atualizarPontos = async () => {
    console.log("Atualizando pontos com base nas previsões...");

    const updates = {}; // Estrutura para acumular todas as atualizações a serem feitas no Firebase

    // Buscar a estrutura de previsões no Firebase
    const predictionsSnapshot = await get(ref(database, 'Predictions'));
    const predictionsData = predictionsSnapshot.val();

    if (!predictionsData) {
      console.log("Nenhuma previsão encontrada.");
      return;
    }

    // Iterar sobre todas as datas disponíveis nas previsões
    Object.entries(predictionsData).forEach(([ano, anoData]) => {
      Object.entries(anoData).forEach(([mes, mesData]) => {
        Object.entries(mesData).forEach(([dia, diaData]) => {
          const dataFormatada = `${ano}/${mes}/${dia}`;

          // Iterar sobre todos os usuários para essa data
          Object.entries(diaData).forEach(([userId, userPredictions]) => {
            let totalPontos = 0;

            // Iterar sobre todos os jogos nas previsões do usuário
            Object.entries(userPredictions.jogos || {}).forEach(([jogoId, jogoObj]) => {
              Object.entries(jogoObj).forEach(([predictionId, prediction]) => {
                if (prediction.status === "Acertou" && !prediction.PontoContabilizado) {
                  totalPontos += prediction.pointsEarned || 3; // Adiciona os pontos ganhos pela previsão correta

                  // Preparar para marcar a previsão como contabilizada no Firebase
                  const predictionPath = `Predictions/${dataFormatada}/${userId}/jogos/${jogoId}/${predictionId}/PontoContabilizado`;
                  updates[predictionPath] = true;
                }
              });
            });

            if (totalPontos > 0) {
              // Atualizar os pontos do usuário na liga
              const pontosLigaPath = `/ligas/-Nu0obu4kFv5j9bR_Q1i/usuarios/${userId}/pontos`;
              const pontosAtuais = liga.membros.find(membro => membro.id === userId)?.pontos || 0;
              updates[pontosLigaPath] = pontosAtuais + totalPontos;
            }
          });
        });
      });
    });

    // Aplicar as atualizações no Firebase
    if (Object.keys(updates).length > 0) {
      await update(ref(database), updates);
      console.log("Pontos e previsões atualizados com sucesso no Firebase.");
      
      // Atualizar o estado local
      const membrosAtualizados = liga.membros.map(membro => {
        if (updates[`/ligas/-Nu0obu4kFv5j9bR_Q1i/usuarios/${membro.id}/pontos`]) {
          return {
            ...membro,
            pontos: updates[`/ligas/-Nu0obu4kFv5j9bR_Q1i/usuarios/${membro.id}/pontos`],
          };
        }
        return membro;
      });
      
      setLiga(prevLiga => ({
        ...prevLiga,
        membros: membrosAtualizados,
      }));
    // Exibir mensagem de sucesso para o usuário usando Swal
    Swal.fire({
      icon: 'success',
      title: 'Sucesso!',
      text: 'Pontos atualizados com sucesso.',
  });
} else {
  console.log("Nenhuma atualização de pontos necessária.");
}
};

// Função para lidar com o clique no botão de atualizar pontos
const handleAtualizarPontosClick = () => {
  // Chama a função de atualizar pontos quando o botão é clicado
  atualizarPontos();
  // Exibe a mensagem de informação quando o botão é clicado
  Swal.fire({
      icon: 'info',
      title: 'Informação',
      text: 'Nenhuma atualização de pontos necessária.',
  });
};




const abrirModalPremiacoes = () => {
  // Abrir o modal e preencher os campos com os valores atuais formatados
  setModalShow(true);
  setTimeout(() => {
    document.getElementById('primeiro').value = formatarParaMoedaReal(liga.premiacoes?.primeiro || 0);
    document.getElementById('segundo').value = formatarParaMoedaReal(liga.premiacoes?.segundo || 0);
    document.getElementById('terceiro').value = formatarParaMoedaReal(liga.premiacoes?.terceiro || 0);
    
    const inputs = document.querySelectorAll('.form-control');
    inputs.forEach(input => {
      input.addEventListener('input', function (e) {
        let valorNumerico = this.value.replace(/\D/g, '').replace(/^0+/, '');
        if (valorNumerico.length) {
          valorNumerico = parseInt(valorNumerico, 10).toString();
          let valorFormatado = (valorNumerico / 100).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, '$1.');
          this.value = `R$ ${valorFormatado}`;
        } else {
          this.value = '';
        }
      });
    });
  }, 1);
};

function formatarParaMoedaReal(valor) {
  // Remove caracteres que não sejam números, vírgula ou ponto
  let valorLimpo = valor.toString().replace(/[^\d,.-]/g, '');

  // Troca vírgula por ponto para conversão
  valorLimpo = valorLimpo.replace(",", ".");

  // Converte para número
  const numero = parseFloat(valorLimpo);

  // Verifica se o número é válido
  if (!isNaN(numero)) {
    // Formata para o padrão brasileiro de moeda
    return numero.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  } else {
    return "Valor inválido"; // Mensagem para valor inválido
  }
}


const salvarPremiacoes = () => {
  // Obter os valores como strings formatadas
  const primeiroValor = document.getElementById('primeiro').value;
  const segundoValor = document.getElementById('segundo').value;
  const terceiroValor = document.getElementById('terceiro').value;

  // Atualiza na base de dados como strings
  const premiacoesRef = ref(database, `/ligas/${liga.id}/premiacoes`);
  update(premiacoesRef, {
    primeiro: primeiroValor,
    segundo: segundoValor,
    terceiro: terceiroValor
  }).then(() => {
    Swal.fire('Sucesso', 'Premiações atualizadas com sucesso', 'success');
    setModalShow(false); // Fecha o modal após salvar
  }).catch(error => {
    Swal.fire('Erro', 'Não foi possível atualizar as premiações: ' + error.message, 'error');
  });
};


function removerFormatacao(valor) {
  return parseFloat(valor.replace('R$', '').replace(/\./g, '').replace(',', '.'));
}


const abrirModalAlteracao = () => {
    setModalAlteracaoShow(true);
  };

  const atualizarPosicaoMembro = async () => {
    if (senhaTemporaria === 'admin') {
      // Converter novaPosicao para número e ajustar para índice baseado em zero
      const novaPosicaoIndex = parseInt(novaPosicao, 10) - 1;
  
      // Verificar se a nova posição é válida
      if (novaPosicaoIndex >= 0 && novaPosicaoIndex < liga.membros.length) {
        // Encontrar o membro na lista
        const membroIndex = liga.membros.findIndex(membro => membro.id === membroSelecionado);
        if (membroIndex !== -1) {
          // Copiar a lista de membros para manipulação
          let membrosAtualizados = [...liga.membros];
  
          // Remover o membro de sua posição atual
          const [membroAtualizado] = membrosAtualizados.splice(membroIndex, 1);
  
          // Inserir o membro na nova posição
          membrosAtualizados.splice(novaPosicaoIndex, 0, membroAtualizado);
  
          // Atualizar o estado com a nova lista de membros
          setLiga({...liga, membros: membrosAtualizados});
  
          // Se necessário, atualize também no Firebase...
          // Sua lógica para atualizar no Firebase aqui
  
          setModalAlteracaoShow(false);
          Swal.fire('Sucesso', 'Posição do membro atualizada com sucesso.', 'success');
        } else {
          Swal.fire('Erro', 'Membro não encontrado.', 'error');
        }
      } else {
        Swal.fire('Erro', 'Nova posição inválida.', 'error');
      }
    } else {
      Swal.fire('Erro', 'Senha temporária incorreta.', 'error');
    }
  };
  



  const LigaDetalhesComponent = isMobile ? LigaDetalhesMobile : LigaDetalhes;

  return (
    <div className="container mt-5">
      <LigaDetalhesComponent dadosDaLiga={liga} atualizarPontos={handleAtualizarPontosClick} salvarPremiacoes={abrirModalPremiacoes} atualizarPosicaoMembro={abrirModalAlteracao} />


      <Modal show={modalShow} onHide={() => setModalShow(false)}>
    <Modal.Header closeButton>
        <Modal.Title>Definir Premiações</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <form>
            <div className="form-group">
                <label htmlFor="primeiro">1º Lugar:</label>
                <input type="text" className="form-control" id="primeiro" />
            </div>
            <div className="form-group">
                <label htmlFor="segundo">2º Lugar:</label>
                <input type="text" className="form-control" id="segundo" />
            </div>
            <div className="form-group">
                <label htmlFor="terceiro">3º Lugar:</label>
                <input type="text" className="form-control" id="terceiro" />
            </div>
        </form>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalShow(false)}>Fechar</Button>
        <Button variant="primary" onClick={salvarPremiacoes}>Salvar</Button>
    </Modal.Footer>
</Modal>

<Modal show={modalAlteracaoShow} onHide={() => setModalAlteracaoShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Alterar Posição do Membro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Selecione o Membro:</Form.Label>
              <Form.Control as="select" value={membroSelecionado} onChange={e => setMembroSelecionado(e.target.value)}>
                {liga.membros.map((membro, index) => (
                  <option key={index} value={membro.id}>{membro.apelido}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Nova Posição:</Form.Label>
              <Form.Control type="number" value={novaPosicao} onChange={e => setNovaPosicao(e.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Senha Temporária:</Form.Label>
              <Form.Control type="password" value={senhaTemporaria} onChange={e => setSenhaTemporaria(e.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalAlteracaoShow(false)}>Cancelar</Button>
          <Button variant="primary" onClick={atualizarPosicaoMembro}>Salvar</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default AtualizaPontosLiga;
