import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth, database } from '../firebase/firebaseConfig';
import { createUserWithEmailAndPassword,updateProfile  } from 'firebase/auth';
import { ref, set, get } from 'firebase/database';
import Swal from 'sweetalert2'; 
import './SignUpAdmin.css';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
 
  const [apelido, setApelido] = useState('');
  const [senha, setSenha] = useState('');
  const [isAdmin, setIsAdmin] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Verificar se o nome da conta contém espaços
    if (nome.includes(' ')) {
      Swal.fire({
        icon: 'error',
        title: 'Nome da Conta Inválido',
        text: 'O Nome da Conta não pode conter espaços.',
      });
      return;
    }
  
    // Verifica se a senha contém apenas números
    if (!/^\d+$/.test(senha)) {
      Swal.fire({
        icon: 'error',
        title: 'Senha Inválida',
        text: 'A senha deve conter apenas números.',
      });
      return;
    }
  
    // Verificar unicidade do apelido
    const apelidoRef = ref(database, `apelidos/${apelido}`);
    const apelidoSnapshot = await get(apelidoRef);
    if (apelidoSnapshot.exists()) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'O apelido já está em uso. Por favor, escolha outro.',
      });
      return;
    }
  
    // Verificar unicidade do nome da conta
    const usuariosRef = ref(database, 'usuarios');
    const usuariosSnapshot = await get(usuariosRef);
    if (usuariosSnapshot.exists()) {
      const usuarios = usuariosSnapshot.val();
      const nomesContas = Object.values(usuarios).map(usuario => usuario.nome);
      if (nomesContas.includes(nome)) {
        Swal.fire({
          icon: 'error',
          title: 'Nome da Conta Indisponível',
          text: 'Este Nome da Conta já está em uso. Por favor, escolha outro.',
        });
        return;
      }
    }
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, senha);
      const user = userCredential.user;

      // Atualização do perfil do usuário com o displayName
      await updateProfile(user, {
        displayName: nome,  // Aqui você pode usar o nome que o usuário inseriu no formulário
      });
  
      await set(ref(database, `usuarios/${user.uid}`), {
        nome,
        apelido,
        email,
        isAdmin,
      });
  
      await set(apelidoRef, user.uid);
  
      Swal.fire('Cadastro Realizado!', 'Seu cadastro foi realizado com sucesso!', 'success');
      navigate('/dashboard');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        Swal.fire({
          icon: 'error',
          title: 'Erro no Cadastro',
          text: 'O e-mail fornecido já está em uso.',
        });
      } else {
        console.error('Erro ao criar usuário:', error.message);
        Swal.fire({
          icon: 'error',
          title: 'Erro no Cadastro',
          text: `Erro ao realizar cadastro: ${error.message}`,
        });
      }
    }
  };
  

  return (
    <div className="signup-container">
      <Link to="/" className="back-to-login-link">Voltar para a tela inicial</Link>
      <h2 className="signup-title">Cadastro</h2>
      <form onSubmit={handleSubmit} className="signup-form">
        <div className="form-group">
          <label htmlFor="nome">Nome Da Conta</label>
          <input
            id="nome"
            type="text"
            placeholder="Nome Da Conta"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="apelido">Apelido</label>
          <input
            id="apelido"
            type="text"
            placeholder="Apelido"
            value={apelido}
            onChange={(e) => setApelido(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="senha">Senha (apenas números)</label>
          <input
            id="senha"
            type="password"
            placeholder="Senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="admin">Administrador</label>
          <input
            id="admin"
            type="text"
            value="Admin"
            readOnly
            disabled
          />
        </div>
        <button type="submit" className="signup-button">Registrar Como Administrador</button>
      </form>
    </div>
  );
};

export default SignUp;
