import React, { useState, useEffect } from 'react';
import { Container, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import useIsMobile from '../hooks/useIsMobile';
import MeusPalpitesMobile from './MeusPalpitesMobile';
import './meuspalpites.css';

const MeusPalpites = () => {
  const [palpitadores, setPalpitadores] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const navigate = useNavigate();
  const auth = getAuth();
  const isMobile = useIsMobile();

  useEffect(() => {
    const ano = selectedDate.getFullYear();
    const mes = String(selectedDate.getMonth() + 1).padStart(2, '0');
    const dia = String(selectedDate.getDate()).padStart(2, '0');
  
    // Caminho ajustado para combinar com a nova estrutura
    const predictionsPath = `Predictions/${ano}/${mes}/${dia}`;
  
    onValue(ref(database, predictionsPath), (snapshot) => {
      const tempPalpitadores = [];
  
      snapshot.forEach((userSnapshot) => {
        const userID = userSnapshot.key;
        const userGames = userSnapshot.child("jogos").val() || {};
  
        Object.keys(userGames).forEach((gameID) => {
          const predictions = userGames[gameID] || {};
  
          Object.keys(predictions).forEach((predictionId) => {
            const prediction = predictions[predictionId];
            const palpitadorIndex = tempPalpitadores.findIndex(p => p.userID === userID);
  
            if (palpitadorIndex > -1) {
              tempPalpitadores[palpitadorIndex].quantidade += 1;
            } else {
              // Supõe-se que userApelido está disponível em cada predição
              tempPalpitadores.push({ userApelido: prediction.userApelido, quantidade: 1, userID });
            }
          });
        });
      });
  
      setPalpitadores(tempPalpitadores);
    });
  }, [selectedDate, database]);
  

  const handleRowClick = (userID, userApelido) => {
    sessionStorage.setItem('selectedUserApelido', userApelido);
    const adjustedDate = new Date(selectedDate.getTime() - (selectedDate.getTimezoneOffset() * 60000));
    navigate(`/detalhes-usuario/${userID}?data=${adjustedDate.toISOString().split('T')[0]}`);
  };

  if (isMobile) {
    // Renderiza o componente para dispositivos móveis dentro da lógica de retorno
    return <MeusPalpitesMobile />;
  }

  
  

  return (
    <Container className="my-5">
    <DatePicker selected={selectedDate} onChange={date => setSelectedDate(date)} dateFormat="dd/MM/yyyy" />
    <Table striped bordered hover className="mt-2 tabela-verde-empresarial">
      <thead>
        <tr>
          <th>Palpitador</th>
          <th>Quantidade de Palpites</th>
        </tr>
      </thead>
      <tbody>
        {palpitadores.map(({ userApelido, quantidade, userID }, index) => (
          <tr key={index} onClick={() => handleRowClick(userID, userApelido)} style={{ cursor: 'pointer' }}>
            <td><strong>{userApelido}</strong></td>
            <td>{quantidade} - ver seus palpites</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Container>
);
};
export default MeusPalpites;

