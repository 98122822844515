import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './MobileNavbar.css';
import { useAuth } from './contexts/AuthContext';
import { database } from './firebase/firebaseConfig';
import { ref, onValue } from 'firebase/database'; // Importar onValue em vez de get
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faPlus, faCog, faEdit, faTable } from '@fortawesome/free-solid-svg-icons';

const MobileNavbar = () => {
  const { currentUser } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(true); 

  useEffect(() => {
    if (currentUser) {
      const userRef = ref(database, `usuarios/${currentUser.uid}`);

      // Listener para mudanças no status de admin do usuário
      const unsubscribe = onValue(userRef, (snapshot) => {
        if (snapshot.exists()) {
          setIsAdmin(!!snapshot.val().isAdmin);
        }
        setLoading(false);
      });

      // Função de limpeza para remover o listener
      return () => unsubscribe();
    } else {
      setLoading(false); // Garante que o loading seja definido como false se não houver usuário
    }
  }, [currentUser]);

  if (!currentUser || loading || !isVisible) { // Verifica a visibilidade junto com as outras condições
    return null;
  }

  return (
    <nav className="mobile-navbar">
      <Link to="/" className="mobile-nav-link"><FontAwesomeIcon icon={faHome} /></Link>
      <Link to="/meus-palpites" className="mobile-nav-link"><FontAwesomeIcon icon={faEdit} /></Link>
      {isAdmin && <Link to="/adicionar" className="mobile-nav-link"><FontAwesomeIcon icon={faPlus} /></Link>}
      {isAdmin && <Link to="/adm-palpites" className="mobile-nav-link"><FontAwesomeIcon icon={faCog} /></Link>}
      <Link to="/profile" className="mobile-nav-link"><FontAwesomeIcon icon={faUser} /></Link>
      
      <Link to="/tabela-da-liga" className="floating-button">
  <FontAwesomeIcon icon={faTable} />
  Tabela
</Link>

    </nav>
  );
};

export default MobileNavbar;
