import React, { useState, useEffect } from 'react';
import { database } from '../firebase/firebaseConfig';
import { ref, get, set,push } from 'firebase/database';
import { useAuth } from '../contexts/AuthContext';
import Swal from 'sweetalert2';
import './Adicionar.css';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { v4 as uuidv4 } from 'uuid';
import MatchFormMobile from './MatchFormMobile';
import useIsMobile from '../hooks/useIsMobile';

const MatchForm = () => {
  const { currentUser } = useAuth();
  const [matches, setMatches] = useState([]);
  const [selectedMatches, setSelectedMatches] = useState([]);
  const [userApelido, setUserApelido] = useState('');
  const isMobile = useIsMobile();

  useEffect(() => {
    const fetchUserApelido = async () => {
      if (currentUser?.uid) {
        const userRef = ref(database, `usuarios/${currentUser.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists() && snapshot.val().apelido) {
          setUserApelido(snapshot.val().apelido);
        }
      }
    };

    fetchUserApelido();
  }, [currentUser, database]);

  const handleAddMatch = () => {
    const newMatch = { id: uuidv4(), teamA: '', teamB: '', date: '', time: '' };
    setMatches(m => [...m, newMatch]);
  };

  const addInitialMatch = () => {
    const newMatch = { id: uuidv4(), teamA: '', teamB: '', date: '', time: '' };
    setMatches([newMatch]);
  };

  const handleInputChange = (index, event) => {
    const newMatches = matches.map((match, matchIndex) => {
      if (index === matchIndex) {
        return { ...match, [event.target.name]: event.target.value };
      }
      return match;
    });
    setMatches(newMatches);
  };

  const handleSelectMatch = (id) => {
    setSelectedMatches(prevSelected => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(matchId => matchId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleDeleteSelectedMatches = () => {
    setMatches(matches.filter(match => !selectedMatches.includes(match.id)));
    setSelectedMatches([]);
  };

  const handleSelectAllMatches = (event) => {
    if (event.target.checked) {
      setSelectedMatches(matches.map(match => match.id));
    } else {
      setSelectedMatches([]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const isAnyFieldEmpty = matches.some(match => !match.teamA || !match.teamB || !match.date || !match.time);
  
    if (isAnyFieldEmpty) {
      Swal.fire('Erro', 'Todos os campos dos jogos devem ser preenchidos antes de enviar.', 'error');
      return;
    }
  
    for (const match of matches) {
      const dateTime = new Date(`${match.date}T${match.time}`).toISOString(); // Mantém o formato ISO para dateTime
  
      const matchData = {
        team1Name: match.teamA, // Ajusta para team1Name
        team2Name: match.teamB, // Ajusta para team2Name
        dateTime, // Mantém o formato ISO
        result1: 'pendente', // Adiciona result1 como 'pendente'
        result2: 'pendente', // Adiciona result2 como 'pendente'
        createdByUserId: currentUser?.uid, // Mantém createdByUserId
        createdByUserApelido: userApelido || 'usuarioAnonimo' // Utiliza apelido ou valor padrão
      };
  
      try {
        const year = format(dateTime, "yyyy", { locale: pt });
        const month = format(dateTime, "MM", { locale: pt });
        const day = format(dateTime, "dd", { locale: pt });
  
        const matchesRef = ref(database, `Matches/${year}/${month}/${day}`);
        await set(push(matchesRef), matchData);
  
        Swal.fire('Sucesso!', 'Partida(s) adicionada(s) com sucesso!', 'success');
      } catch (error) {
        Swal.fire('Erro', 'Houve um problema ao adicionar a(s) partida(s).', 'error');
        console.error('Erro ao salvar partida no Firebase:', error);
      }
    }
  
    setMatches([]);
    setSelectedMatches([]);
    addInitialMatch(); // Reinicia com um novo jogo após a submissão
  };
  


  if (isMobile) {
    return <MatchFormMobile {...{ database, currentUser, matches, selectedMatches, handleInputChange, handleSelectMatch, handleSelectAllMatches, handleAddMatch, handleDeleteSelectedMatches, handleSubmit }} />;
  }

  return (
    <div className="match-form-container">
      <form onSubmit={handleSubmit} className="match-form">
        <div className="select-all-container">
          <input type="checkbox" onChange={handleSelectAllMatches} checked={selectedMatches.length === matches.length} />
          <label>Selecionar Todos (apenas para exclusão)</label>
          <button type="button" onClick={handleAddMatch} className="add-match-button">Adicionar outro jogo</button>
          <button type="submit" className="submit-matches-button">Enviar palpites</button>
          <button type="button" onClick={handleDeleteSelectedMatches} className="delete-selected-button">Excluir Selecionados</button>
        </div>
        {matches.map((match, index) => (
          <div key={match.id} className="match-entry">
            <input type="checkbox" checked={selectedMatches.includes(match.id)} onChange={() => handleSelectMatch(match.id)} />
            <input className="match-input" name="teamA" placeholder="Time da casa" value={match.teamA} onChange={event => handleInputChange(index, event)} />
            <input className="match-input" name="teamB" placeholder="Time de fora" value={match.teamB} onChange={event => handleInputChange(index, event)} />
            <input className="match-input" type="date" name="date" value={match.date} onChange={event => handleInputChange(index, event)} />
            <input className="match-input" type="time" name="time" value={match.time} onChange={event => handleInputChange(index, event)} />
          </div>
        ))}
      </form>
    </div>
  );
};

export default MatchForm;
