import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

import   './LigaDetalhes.css';

const LigaDetalhes = ({ dadosDaLiga, atualizarPontos, salvarPremiacoes,atualizarPosicaoMembro }) => {
  // Função para determinar a classe de estilo com base no índice do membro
  const getRowClass = (index) => {
    switch (index) {
      case 0: return "table-gold";
      case 1: return "table-silver";
      case 2: return "table-bronze";
      default: return "";
    }
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>{dadosDaLiga.nome}</h2>
        <button className="btn btn-primary" onClick={atualizarPontos}>Atualizar Pontos</button>
        <button className="btn btn-secondary btn-sm" onClick={salvarPremiacoes}>Definir Premiações</button>
        <button className="btn btn-secondary btn-sm" onClick={atualizarPosicaoMembro}>admin</button>
      </div>
      {dadosDaLiga && dadosDaLiga.membros && (
        <div className="table-responsive">
          <table className="table table-striped table-green">
            <thead>
              <tr>
                <th>#</th>
                <th>Usuário</th>
                <th>Pontos</th>
                <th>Premiação</th>
              
              </tr>
            </thead>
            <tbody>
              {dadosDaLiga.membros.map((membro, index) => (
                <tr key={membro.id} className={getRowClass(index)}>
                  <td>{index + 1}</td>
                  <td>{membro.apelido}</td>
                  <td>{membro.pontos}</td>
                  <td>{index === 0 ? `R$ ${dadosDaLiga.premiacoes.primeiro}` :
                      index === 1 ? `R$ ${dadosDaLiga.premiacoes.segundo}` :
                      index === 2 ? `R$ ${dadosDaLiga.premiacoes.terceiro}` : '-'}</td>
                  <td>
                    {index === 0 ? <FontAwesomeIcon icon={faTrophy} style={{ color: '#FFD700'}} /> :
                    index === 1 ? <FontAwesomeIcon icon={faTrophy} style={{ color: 'silver' }} /> :
                    index === 2 ? <FontAwesomeIcon icon={faTrophy} style={{ color: '#cd7f32' }} /> : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default LigaDetalhes;
