import React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import SignUp from './components/Auth/SignUp';
import Login from './components/Auth/Login';
import SignUpAdmin from './components/Auth/SignUpAdmin';
import HomePage from './components/pages/HomePage';
import Dashboard from './components/pages/Dashboard';
import ProfilePage from './components/pages/ProfilePage';
import PostLoginPage from './components/Auth/PostLoginModal';
import Adicionar from './components/pages/Adicionar';
import AdminPalpites from './components/pages/AdminPalpites';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import PasswordReset from './components/Auth/PasswordReset';
import { useAuth } from './components/contexts/AuthContext';
import MobileLayout from './components/layouts/MobileLayout'; // Supondo que você tenha um layout móvel
import WebLayout from './components/layouts/WebLayout'; // Supondo que você tenha um layout web
import CriarEmBreve from './components/pages/CriarEmBreve'; 
import PostLoginModalAdm from './components/Auth/PostLoginModalAdm';
import LoginTemporario from './components/pages/LoginTemporario';
import CriaLiga from './components/pages/CriaLiga';
import TabelaDaLiga from './components/pages/TabelaDaLiga';
import MeusPalpites from './components/pages/meuspalpites';
import PalpitesDetalhes from './components/pages/PalpitesDetalhes';
import MaintenanceScreen from './components/pages/MaintenanceScreen';

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};


const Routes = () => {
  const { loading } = useAuth();
  const isMobile = isMobileDevice(); // Usa a função helper para detectar dispositivo móvel
  
  

  if (loading) {
    return <div>Carregando...</div>;
  }

  const Layout = isMobile ? MobileLayout : WebLayout; // Escolhe o layout com base no tipo de dispositivo

  return (
    <Layout> {/* O layout escolhido envolve suas rotas */}
      <RouterRoutes>
        <Route path="/" element={<HomePage />} />
        <Route path="/cadastro" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/cadastro-adm" element={<SignUpAdmin />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/adicionar" element={<ProtectedRoute><Adicionar/></ProtectedRoute>}/>
        <Route path='/adm-palpites' element={<ProtectedRoute><AdminPalpites/></ProtectedRoute>} />
        <Route path='/tabela-da-liga' element={<ProtectedRoute><TabelaDaLiga/></ProtectedRoute>}/>
        <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
        <Route path="/post-login-adm" element={<PostLoginModalAdm />}/>
        <Route path="/post-login" element={<PostLoginPage />} />
        <Route path="/criar-em-breve" element={<ProtectedRoute><CriarEmBreve/></ProtectedRoute>} /> 
        <Route path="/cria-liga" element={<ProtectedRoute><CriaLiga /></ProtectedRoute>} />
        <Route path="/meus-palpites" element={<ProtectedRoute><MeusPalpites/></ProtectedRoute>} />
        <Route path="/login-temporario" element={<ProtectedRoute><LoginTemporario /></ProtectedRoute>} />
        <Route path="/detalhes-usuario/:userID" element={<ProtectedRoute><PalpitesDetalhes /></ProtectedRoute>} /> 
       
        { /*   <Route path="*" element={<MaintenanceScreen />} />  */}
      </RouterRoutes>
    </Layout>
  );
};

export default Routes;
