import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../firebase/firebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';
import Swal from 'sweetalert2';
import './PasswordReset.css'; // Assumindo que os estilos estarão em PasswordReset.css

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      Swal.fire({
        icon: 'success',
        title: 'E-mail Enviado!',
        text: 'Verifique sua caixa de entrada para instruções de redefinição de senha.',
      });
      navigate('/login'); // Redirecione o usuário para a página de login após o envio do e-mail
    } catch (error) {
      console.error("Erro ao enviar e-mail de redefinição de senha:", error);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Não foi possível enviar o e-mail de redefinição de senha. Por favor, tente novamente.',
      });
    }
  };

  return (
    <div className="password-reset-container">
      <h2>Recuperar Senha</h2>
      <form onSubmit={handleSubmit} className="password-reset-form">
        <div className="form-group">
          <input
            type="email"
            placeholder="Digite seu e-mail"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="password-reset-button">Enviar Link de Recuperação</button>
        <Link to="/login" className="back-to-login-link">Voltar para o Login</Link>
      </form>
    </div>
  );
};

export default PasswordReset;
