import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { database } from '../firebase/firebaseConfig';
import { ref, onValue, update } from 'firebase/database';
import { Button, Form, ListGroup, Modal, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';

const EditarLiga = () => {
    const [ligas, setLigas] = useState({});
    const [usuarios, setUsuarios] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [ligaEditavel, setLigaEditavel] = useState({ id: null, nome: '', usuarios: {} });

    useEffect(() => {
        onValue(ref(database, 'ligas'), snapshot => setLigas(snapshot.val() || {}));
        onValue(ref(database, 'usuarios'), snapshot => {
            const fetchedUsuarios = snapshot.val() || {};
            setUsuarios(Object.entries(fetchedUsuarios).map(([key, value]) => ({ id: key, ...value })));
        });
    }, []);

    const abrirModal = (ligaId) => {
        setLigaEditavel({ id: ligaId, ...ligas[ligaId] });
        setShowModal(true);
    };

    const adicionarUsuarioALiga = (usuarioId) => {
        setLigaEditavel(prevState => ({
            ...prevState,
            usuarios: { ...prevState.usuarios, [usuarioId]: { pontos: 0 } }
        }));
    };

    const removerUsuarioDaLiga = (usuarioId) => {
        const { [usuarioId]: usuarioRemovido, ...resto } = ligaEditavel.usuarios;
        setLigaEditavel(prevState => ({ ...prevState, usuarios: resto }));
    };

    const salvarLiga = () => {
        if (ligaEditavel.id) {
            update(ref(database, `ligas/${ligaEditavel.id}`), { usuarios: ligaEditavel.usuarios }).then(() => {
                Swal.fire('Sucesso', 'Liga atualizada com sucesso.', 'success');
                setShowModal(false);
            });
        }
    };

    return (
        <div>
            <h3>Ligas</h3>
            <ListGroup>
                {Object.entries(ligas).map(([id, liga]) => (
                    <ListGroup.Item key={id}>
                        {liga.nome}
                        <Button variant="info" onClick={() => abrirModal(id)}>Editar Usuários</Button>
                    </ListGroup.Item>
                ))}
            </ListGroup>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Usuários da Liga: {ligaEditavel.nome}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <h5>Usuários Atuais</h5>
                            <ListGroup>
                                {Object.keys(ligaEditavel.usuarios).map(usuarioId => {
                                    const usuario = usuarios.find(u => u.id === usuarioId);
                                    return usuario ? (
                                        <ListGroup.Item key={usuarioId}>
                                            {usuario.apelido}
                                            <Button variant="danger" size="sm" onClick={() => removerUsuarioDaLiga(usuarioId)}>Remover</Button>
                                        </ListGroup.Item>
                                    ) : null;
                                })}
                            </ListGroup>
                        </Col>
                        <Col>
                            <h5>Adicionar Usuários</h5>
                            <ListGroup>
                                {usuarios.filter(u => !Object.keys(ligaEditavel.usuarios).includes(u.id)).map(usuario => (
                                    <ListGroup.Item key={usuario.id}>
                                        {usuario.apelido}
                                        <Button variant="primary" size="sm" onClick={() => adicionarUsuarioALiga(usuario.id)}>Adicionar</Button>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Fechar</Button>
                    <Button variant="primary" onClick={salvarLiga}>Salvar Alterações</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EditarLiga;
