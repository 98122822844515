import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importe o useNavigate
import 'bootstrap/dist/css/bootstrap.min.css';

const LoginTemporario = () => {
  const navigate = useNavigate(); // Cria a instância do useNavigate

  // Definindo usuário e senha válidos
  const VALID_USER = 'admin';
  const VALID_PASSWORD = 'admin';

  // Estados para armazenar as entradas do usuário
  const [usuario, setUsuario] = useState('');
  const [senha, setSenha] = useState('');
  const [erro, setErro] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    setErro('');

    // Verifica se as credenciais correspondem às válidas
    if (usuario === VALID_USER && senha === VALID_PASSWORD) {
      // Redireciona para a tela "Criar Liga"
      navigate('/cria-liga'); // Use o caminho correto para sua tela "Criar Liga"
    } else {
      // Define uma mensagem de erro se as credenciais estiverem incorretas
      setErro('Usuário ou senha incorretos!');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Login Temporário</h2>
      {erro && <div className="alert alert-danger" role="alert">{erro}</div>}
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="usuario">Usuário</label>
          <input
            type="text"
            className="form-control"
            id="usuario"
            value={usuario}
            onChange={(e) => setUsuario(e.target.value)}
            placeholder="Digite o usuário"
          />
        </div>
        <div className="form-group">
          <label htmlFor="senha">Senha</label>
          <input
            type="password"
            className="form-control"
            id="senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            placeholder="Digite a senha"
          />
        </div>
        <button type="submit" className="btn btn-primary">Login</button>
      </form>
    </div>
  );
};

export default LoginTemporario;
