import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, onValue, set,get } from 'firebase/database';
import { format, addDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import './Dashboard.css'; // Assegure-se de que o arquivo CSS esteja correto
import DashboardMobile from './DashboardMobile';
import useIsMobile from '../hooks/useIsMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import AdComponent from './AdComponent';



const Dashboard = () => {
  const isMobile = useIsMobile();
  const [currentUser, setCurrentUser] = useState(null);
  const [matches, setMatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [message, setMessage] = useState('');
  const [palpites, setPalpites] = useState({}); // Inicialização do estado palpites
  const [palpitesEnviados, setPalpitesEnviados] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);

  

  const database = getDatabase();
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      setCurrentUser(user);
    });
  }, [auth]);

  useEffect(() => {
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
    const day = String(selectedDate.getDate()).padStart(2, '0');
  
    const matchesPath = `Matches/${year}/${month}/${day}`;
  
    setIsLoading(true);
  
    const unsubscribe = onValue(ref(database, matchesPath), snapshot => {
      const matchesData = [];
    
      snapshot.forEach(childSnapshot => {
        const matchID = childSnapshot.key;
        const matchData = childSnapshot.val();
        matchesData.push({
          ...matchData,
          matchID,
          palpiteA: palpites[matchID]?.palpiteA || '',
          palpiteB: palpites[matchID]?.palpiteB || ''
        });
      });
    
      // Ordena os jogos pela hora mais recente
      matchesData.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
    
      setMatches(matchesData);
      setIsLoading(false);
      setMessage(matchesData.length ? '' : `Nenhuma partida encontrada para ${format(selectedDate, 'dd/MM/yyyy', { locale: ptBR })}.`);
    });
    
  
    return () => unsubscribe();
  }, [database, selectedDate, palpites]); // Adicione palpites como dependência

  useEffect(() => {
    if (!currentUser) return;
  
    const dateFormatted = format(selectedDate, 'yyyy/MM/dd');
    const predictionsPath = `/Predictions/${dateFormatted}/${currentUser.uid}/jogos`;
    const predictionsRef = ref(database, predictionsPath);
  
    get(predictionsRef).then(snapshot => {
      if (snapshot.exists()) {
        const sentPredictions = Object.keys(snapshot.val()); // IDs dos jogos dos palpites enviados
        setPalpitesEnviados(sentPredictions);
      }
    }).catch(error => {
      console.error("Erro ao carregar palpites enviados: ", error);
    });
  }, [currentUser, selectedDate, database]); // Removidas as dependências `palpites` e `palpitesEnviados`
  
  

 
  const handlePalpiteChange = (matchID, team, value) => {
    setPalpites(prevState => ({
      ...prevState,
      [matchID]: {
        ...prevState[matchID],
        [team]: value
      }
    }));
  };


  const handleSubmit = async (matchID) => {
    try {
      const match = matches.find(m => m.matchID === matchID);
      const palpite = palpites[matchID];
  
      if (!palpite || !palpite.palpiteA || !palpite.palpiteB) {
        Swal.fire('Erro!', 'Você precisa digitar ambos os palpites antes de enviar.', 'error');
        return;
      }
  
      if (!currentUser) {
        Swal.fire('Erro!', 'Você precisa estar logado para enviar um palpite.', 'error');
        return;
      }
  
      // Verifica o apelido do usuário no Firebase
      const userApelidoPath = `usuarios/${currentUser.uid}/apelido`;
      const apelidoSnapshot = await get(ref(database, userApelidoPath));
      const userApelido = apelidoSnapshot.exists() ? apelidoSnapshot.val() : 'Anônimo';
  
      const dateFormatted = format(selectedDate, 'yyyy/MM/dd');
      
      // Verifica se já existe um palpite para esse jogo e usuário
      const existingPredictionsPath = `/Predictions/${dateFormatted}/${currentUser.uid}/jogos/${matchID}`;
      const existingPredictionsSnapshot = await get(ref(database, existingPredictionsPath));
      if (existingPredictionsSnapshot.exists()) {
        Swal.fire('Atenção!', 'Você já enviou um palpite para este jogo.', 'warning');
        return;
      }
  
      const predictionID = `prediction-${Date.now()}`;
      const predictionPath = `${existingPredictionsPath}/${predictionID}`;
  
      const newPrediction = {
        userID: currentUser.uid,
        userApelido,
        matchID,
        palpiteTeam1: parseInt(palpite.palpiteA, 10),
        palpiteTeam2: parseInt(palpite.palpiteB, 10),
        pointsEarned: 0,
        gameDateTime: match.dateTime,
        team1Name: match.team1Name,
        team2Name: match.team2Name,
        status: 'Aguardando',
        pontoContabilizado: false,
        enviado: true 
      };
  
      await set(ref(database, predictionPath), newPrediction);
      Swal.fire('Sucesso!', 'Palpite enviado com sucesso!', 'success');
  
      // Limpa os palpites após o envio
      setPalpites(prevPalpites => ({
        ...prevPalpites,
        [matchID]: { palpiteA: '', palpiteB: '' }
      }));

      setPalpitesEnviados([...palpitesEnviados, matchID]);

      // Força a atualização da interface
      setForceUpdate(prevState => !prevState);
    } catch (error) {
      console.error("Erro ao enviar palpite: ", error);
      Swal.fire('Erro!', 'Houve um problema ao enviar seu palpite.', 'error');
    }
  };


  


  const handleKeyDown = (event) => {
    // Se as teclas de seta para cima ou para baixo forem pressionadas, previne a seleção de texto
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  };

  
  
  const handleSetToday = () => setSelectedDate(new Date());
  const handleSetTomorrow = () => setSelectedDate(addDays(new Date(), 1));

  if (isMobile) {
    // Renderiza o componente para dispositivos móveis dentro da lógica de retorno
    return <DashboardMobile />;
  }




  return (
    <div className="dashboard-web-container">
      <div className="dashboard-web-controls">
    

        <DatePicker
          selected={selectedDate}
          onChange={date => setSelectedDate(date)}
          locale={ptBR}
          dateFormat="dd/MM/yyyy"
        />
        <button onClick={() => setSelectedDate(new Date())} className="dashboard-web-btn">Hoje</button>
        <button onClick={() => setSelectedDate(addDays(new Date(), 1))} className="dashboard-web-btn">Amanhã</button>
        
      </div>
      {isLoading && <p>Carregando jogos...</p>}
      {!isLoading && matches.length === 0 && <p>{message}</p>}
      <div className="dashboard-web-matches-list">
        {matches.map((match, index) => {
          const matchDateTime = new Date(match.dateTime);
          const currentDateTime = new Date();
          const hasMatchStarted = matchDateTime <= new Date(); // Define se o jogo começou
          const palpiteEnviado = palpitesEnviados.includes(match.matchID);
  
          return (
            <div key={match.matchID} className={`dashboard-web-match ${hasMatchStarted ? 'jogo-iniciado' : ''}`}>
              <div className="dashboard-web-match-header">
                <span className="match-date-time">{format(matchDateTime, 'dd/MM/yyyy HH:mm')}</span>
                <span className="created-by">Criado por: {match.createdByUserApelido}</span>
              </div>
              <div className="dashboard-web-match-info">
                <span className="team-name">{match.team1Name}</span>
                <input
                  type="number"
                  placeholder="TIME 1"
                  value={palpites[match.matchID]?.palpiteA || ''}
                  onChange={(e) => handlePalpiteChange(match.matchID, 'palpiteA', e.target.value)}
                  className="dashboard-web-input"
                  disabled={hasMatchStarted}
                />
                <span className="vs">X</span>
                <input
                  type="number"
                  placeholder="TIME 2"
                  value={palpites[match.matchID]?.palpiteB || ''}
                  onChange={(e) => handlePalpiteChange(match.matchID, 'palpiteB', e.target.value)}
                  className="dashboard-web-input"
                  disabled={hasMatchStarted}
                />
                <span className="team-name">{match.team2Name}</span>
                {!palpiteEnviado ? (
  <>
    <button onClick={() => handleSubmit(match.matchID)} className="dashboard-web-submit-btn">Enviar</button>
    {/* Ícone ao lado do botão */}
    
  </>
) : (
  // Ícone sozinho se o palpite já foi enviado
  <FontAwesomeIcon icon={faCheckCircle} className="check-icon" style={{ color: 'green' }} />
)}

                {hasMatchStarted && <p className="match-started-notice">O jogo já começou</p>}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
      };  

export default Dashboard;
