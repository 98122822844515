// Arquivo: ProfilePage.jsx
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { ref, update, onValue,get } from 'firebase/database';
import { database } from '../firebase/firebaseConfig';
import './ProfilePage.css';
import Swal from 'sweetalert2';
import useIsMobile from '../hooks/useIsMobile';
import ProfileMobile from './ProfileMobile'; // Certifique-se de que este componente está implementado corretamente

const ProfilePage = () => {
  const isMobile = useIsMobile();
  const { logout, currentUser } = useAuth();
  const [userData, setUserData] = useState({});
  const [editing, setEditing] = useState(false);
  const [editedUserData, setEditedUserData] = useState({});

  useEffect(() => {
    // Carrega os dados do usuário atual
    if (currentUser) {
      const userRef = ref(database, `usuarios/${currentUser.uid}`);
      onValue(userRef, (snapshot) => {
        if (snapshot.exists()) {
          setUserData(snapshot.val());
        }
      });
    }
  }, [currentUser]);

  

  const handleLogout = async () => {
    try {
      await logout();
      Swal.fire('Saiu!', 'Você saiu da sua conta com sucesso.', 'success');
    } catch (error) {
      Swal.fire('Erro!', 'Não foi possível sair da conta. Tente novamente.', 'error');
    }
  };

  const handleEdit = () => {
    setEditing(true);
    setEditedUserData(userData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedUserData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSave = async () => {
    // Verifica se o nome contém espaços
    if (/\s/.test(editedUserData.nome)) {
      Swal.fire('Erro!', 'O nome não pode conter espaços.', 'error');
      return;
    }

    // Verifica se o nome já existe (exceto para o próprio usuário)
    const usersRef = ref(database, 'usuarios');
    const snapshot = await get(usersRef);
    let nomeExists = false;
    snapshot.forEach(childSnapshot => {
      if (childSnapshot.key !== currentUser.uid && childSnapshot.val().nome === editedUserData.nome) {
        nomeExists = true;
      }
    });

    if (nomeExists) {
      Swal.fire('Erro!', 'Este nome já está sendo usado por outro usuário.', 'error');
      return;
    }

    // Atualiza os dados do usuário se passar nas validações
    const userRef = ref(database, `usuarios/${currentUser.uid}`);
    await update(userRef, editedUserData);
    setUserData(editedUserData);
    setEditing(false);
    Swal.fire('Sucesso!', 'Perfil atualizado com sucesso.', 'success');
  };

  const handleToggleAdmin = async () => {
    const newAdminStatus = !userData.isAdmin;
    const confirm = await Swal.fire({
      title: 'Tem certeza?',
      text: `Você quer ${newAdminStatus ? 'conceder' : 'remover'} o status de administrador?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, alterar!'
    });

    if (confirm.isConfirmed) {
      const userRef = ref(database, `usuarios/${currentUser.uid}`);
      await update(userRef, { isAdmin: newAdminStatus });
      setUserData({ ...userData, isAdmin: newAdminStatus });
      Swal.fire('Atualizado!', `O status de administrador foi ${newAdminStatus ? 'concedido' : 'removido'}.`, 'success');
    }
  };

  const handleCancelEdit = () => {
    setEditedUserData({ ...userData }); // Reverte para os dados originais do usuário
    setEditing(false); // Desativa o modo de edição
  };

if (isMobile) {
  return (
    <ProfileMobile
      userData={userData}
      handleEdit={handleEdit}
      handleChange={handleChange}
      handleSave={handleSave}
      editing={editing}
      editedUserData={editedUserData}
      handleLogout={handleLogout}
      handleToggleAdmin={handleToggleAdmin}
      handleCancelEdit={handleCancelEdit} // Passando a prop para o componente mobile
    />
  );
}

  return (
    <div className="container mt-5">
      <h1>Página de Perfil</h1>
      {userData && (
        <div>
          <h2>Informações do Usuário</h2>
          <table className="table">
            <tbody>
            <tbody>
              <tr>
                <td>Nome:</td>
                <td>{editing ? <input type="text" name="nome" value={editedUserData.nome || ''} onChange={handleChange} /> : userData.nome}</td>
              </tr>
              <tr>
                <td>Apelido:</td>
                <td>{ userData.apelido}</td>
              </tr>
              
              <tr>
                <td>Email:</td>
                <td>{userData.email}</td>
              </tr>
              <tr>
                <td>É Admin:</td>
                <td>{userData.isAdmin ? 'Sim' : 'Não'}</td>
              </tr>
            </tbody>
            </tbody>
          </table>
          <div className="profile-actions">
            {editing ? (
              <>
                <button onClick={handleSave} className="btn btn-primary">Salvar Alterações</button>
                <button onClick={handleCancelEdit} className="btn btn-secondary">Cancelar</button>
              </>
            ) : (
              <button onClick={handleEdit} className="btn btn-secondary">Editar Perfil</button>
            )}
          <button onClick={handleLogout} className="btn btn-danger">Sair</button>
          <button onClick={handleToggleAdmin} className={`btn ${userData.isAdmin ? 'btn-warning' : 'btn-success'}`}>
            {userData.isAdmin ? 'Remover Admin' : 'Tornar-se Admin'}
          </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
