import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import './LigaDetalhesMobile.css'; // Asegúrese de ter os estilos necessários no seu CSS

const LigaDetalhesMobile = ({ dadosDaLiga, atualizarPontos, salvarPremiacoes }) => {
  const premiacaoIcone = (index) => {
    const cores = ['#FFD700', '#C0C0C0', '#CD7F32']; // Ouro, Prata, Bronze
    return index < 3 ? <FontAwesomeIcon icon={faTrophy} style={{ color: cores[index] }} /> : null;
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col">
          <h2>{dadosDaLiga.nome}</h2>
        </div>
        <div className="col-auto">
          <button onClick={atualizarPontos} className="btn btn-primary btn-sm">Atualizar Pontos</button>
        </div>
        <div className="col-auto">
          <button onClick={salvarPremiacoes} className="btn btn-success btn-sm">Definir Premiações</button>
        </div>
      </div>
      {dadosDaLiga.membros && dadosDaLiga.membros.length > 0 ? (
        <div className="table-responsive">
           <table className="table table-striped table-green">
            <thead>
              <tr>
                <th>#</th>
                <th>Usuário</th>
                <th>Pontos</th>
                <th>Premiação</th>
             
              </tr>
            </thead>
            <tbody>
              {dadosDaLiga.membros.map((membro, index) => (
                <tr key={membro.id}>
                  <td>{index + 1}</td>
                  <td>{membro.apelido}</td>
                  <td>{membro.pontos}</td>
                  <td>{
                    index === 0 ? `R$ ${dadosDaLiga.premiacoes.primeiro}` :
                    index === 1 ? `R$ ${dadosDaLiga.premiacoes.segundo}` :
                    index === 2 ? `R$ ${dadosDaLiga.premiacoes.terceiro}` : '-'
                  }</td>
                  <td>{premiacaoIcone(index)}</td> {/* Adiciona ícone de troféu */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>Nenhum membro encontrado.</div>
      )}
    </div>
  );
};

export default LigaDetalhesMobile;
