// hooks/useIsMobile.js

import { useState, useEffect } from 'react';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Define o estado inicial baseado na largura atual da janela

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Atualiza o estado baseado na largura da janela
    };

    window.addEventListener('resize', checkIfMobile); // Adiciona o listener para o evento de resize
    checkIfMobile(); // Verifica imediatamente ao carregar

    return () => window.removeEventListener('resize', checkIfMobile); // Remove o listener ao desmontar
  }, []);

  return isMobile; // Retorna o estado atual
};

export default useIsMobile;
