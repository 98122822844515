import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate  } from 'react-router-dom';
import { Container, ListGroup, Alert, Button, Form, FormText } from 'react-bootstrap';
import { ref, onValue, update, remove ,get} from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Modal from 'react-modal';
import { FiEdit3, FiTrash2 } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { database } from '../firebase/firebaseConfig';
import './PalpitesDetalhesMobile.css';

Modal.setAppElement('#root');

const PalpitesDetalhesMobile = () => {
  
  const { userID } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [palpitesDetalhes, setPalpitesDetalhes] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editablePalpite, setEditablePalpite] = useState({});
  const queryParams = new URLSearchParams(location.search);
  const dataParam = queryParams.get('data');
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState(null);
  // No início do componente, depois de definir o estado e antes do useEffect
const userApelido = sessionStorage.getItem('selectedUserApelido');


  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
  }, [auth]);

  useEffect(() => {
    if (!dataParam || !userID) {
      console.error("Data ou usuário não fornecidos.");
      return;
    }
  
    const dateFormatted = dataParam.replace(/-/g, '/');
    const predictionsPath = `Predictions/${dateFormatted}/${userID}/jogos`;
  
    onValue(ref(database, predictionsPath), async (snapshot) => {
      const jogosSnapshot = snapshot.val();
      const detalhesPromises = [];
  
      if (jogosSnapshot) {
        Object.keys(jogosSnapshot).forEach((jogoID) => {
          const palpites = jogosSnapshot[jogoID];
  
          Object.keys(palpites).forEach((predictionId) => {
            const palpite = palpites[predictionId];
  
            // Extrair a data do jogo a partir do palpite ou de outra fonte confiável
            const jogoData = new Date(palpite.gameDateTime);
            const ano = jogoData.getFullYear();
            const mes = jogoData.getMonth() + 1; // getMonth() retorna mês de 0 a 11
            const dia = jogoData.getDate();
            const jogoDataFormatted = `${ano}/${mes.toString().padStart(2, '0')}/${dia.toString().padStart(2, '0')}`;
  
            // Adicionando uma promise para cada busca de detalhes do jogo com a data dinâmica
            detalhesPromises.push(
              get(ref(database, `Matches/${jogoDataFormatted}/${jogoID}`)).then((jogoSnapshot) => {
                const jogoInfo = jogoSnapshot.val();
                return {
                  ...palpite,
                  jogoID,
                  predictionId,
                  result1: jogoInfo?.result1 || "Pendente",
                  result2: jogoInfo?.result2 || "Pendente"
                };
              })
            );
          });
        });

        
  
        // Aguardando todas as promises de detalhes dos jogos
        const detalhesResolved = await Promise.all(detalhesPromises);

         // Organiza os palpites pela última hora do jogo
       // Organiza os palpites pela hora mais recente do jogo
       detalhesResolved.sort((a, b) => {
        const timeA = new Date(a.gameDateTime).getHours() * 60 + new Date(a.gameDateTime).getMinutes();
        const timeB = new Date(b.gameDateTime).getHours() * 60 + new Date(b.gameDateTime).getMinutes();
        return timeB - timeA; // Inverte a comparação para obter a hora mais recente primeiro
      });

        setPalpitesDetalhes(detalhesResolved);
      }
    });
  }, [userID, dataParam]);
  
  

  const handleEditarClick = (palpite) => {
    const now = new Date();
    const gameDateTime = new Date(palpite.gameDateTime);
  
    if (currentUser.uid !== palpite.userID) {
      Swal.fire('Aviso!', 'Você só pode editar seus próprios palpites.', 'warning');
      return;
    }
  
    if (now >= gameDateTime) {
      Swal.fire('Aviso!', 'Não é possível editar palpites após o início do jogo.', 'warning');
      return;
    }
  
    setEditablePalpite(palpite);
    setModalIsOpen(true);
  };

  const handleSalvarEdicao = () => {
    const [ano, mes, dia] = dataParam.split('-'); // Corrigido aqui
    const palpiteRef = ref(database, `/Predictions/${ano}/${mes}/${dia}/${userID}/jogos/${editablePalpite.jogoID}/${editablePalpite.predictionId}`);

    update(palpiteRef, { palpiteTeam1: editablePalpite.palpiteTeam1, palpiteTeam2: editablePalpite.palpiteTeam2 }).then(() => {
      setModalIsOpen(false);
      Swal.fire('Sucesso!', 'Palpite atualizado com sucesso.', 'success');
    }).catch(error => {
      console.error('Erro ao atualizar palpite:', error);
      Swal.fire('Erro!', 'Não foi possível atualizar o palpite.', 'error');
    });
  };


  
  const handleExcluirClick = async (palpite) => {
    const now = new Date();
    const gameDateTime = new Date(palpite.gameDateTime);
  
    if (currentUser.uid !== palpite.userID) {
      Swal.fire('Aviso!', 'Você só pode excluir seus próprios palpites.', 'warning');
      return;
    }
  
    if (now >= gameDateTime) {
      Swal.fire('Aviso!', 'Não é possível excluir palpites após o início do jogo.', 'warning');
      return;
    }
  
    const result = await Swal.fire({
      title: 'Tem certeza?',
      text: "Você não poderá desfazer esta ação!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, exclua!',
      cancelButtonText: 'Não, cancele!',
      reverseButtons: true
    });
  
    if (result.isConfirmed) {
      // Extrair ano, mes e dia da dataParam
      const [ano, mes, dia] = dataParam.split('-');
      const palpiteRef = ref(database, `/Predictions/${ano}/${mes}/${dia}/${userID}/jogos/${palpite.jogoID}/${palpite.predictionId}`);
  
      remove(palpiteRef).then(() => {
        setPalpitesDetalhes(palpitesDetalhes.filter(p => p.predictionId !== palpite.predictionId));
        Swal.fire('Excluído!', 'O palpite foi excluído com sucesso.', 'success');
      }).catch(error => {
        console.error('Erro ao excluir palpite:', error);
        Swal.fire('Erro!', 'Não foi possível excluir o palpite.', 'error');
      });
    }
  };

  const handleBackButtonClick = () => {
    navigate(-1); // Isso fará o navegador voltar para a página anterior
  };


  return (
   <Container className="palpite-container">
  <div className="header">
    <span>Palpites de {userApelido} em {dataParam}</span>
    <Button variant="secondary" size="sm" onClick={handleBackButtonClick}>Voltar</Button>
  </div>
  {palpitesDetalhes.length > 0 ? (
    <ListGroup className="palpites-lista">
    {palpitesDetalhes.map((palpite) => (
      <ListGroup.Item
        key={palpite.jogoID}
        className={`palpite-item ${palpite.status === "Acertou" ? "palpite-certo" : palpite.status === "Errou" ? "palpite-errado" : ""}`}
      >
        <div className="info-destaque">
          <div>
            <span>{palpite.team1Name}</span> <span>vs</span> <span>{palpite.team2Name}</span>
            <span className="spacer">
              {new Date(palpite.gameDateTime).toLocaleDateString()} às {new Date(palpite.gameDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            </span>
          </div>
          <div>
            <span>Palpite: {palpite.palpiteTeam1} - {palpite.palpiteTeam2}</span>
            <br />
            <span className={`${palpite.status.toLowerCase()}`}>{palpite.status}</span>
            {palpite.status === "Acertou" && <FormText style={{marginLeft: '25px', color: 'white',fontWeight:'bold'}}>  3+ PONTOS</FormText>}
            <br />
            <span>Resultado real: {
              palpite.result1 === "Pendente" && palpite.result2 === "Pendente" ?
              <span className="aguardando">Pendente</span> :
              `${palpite.result1} - ${palpite.result2}`
            }</span>
          </div>
        </div>
        <div>
          {palpite.userID === currentUser.uid && (
            <>
              <FiEdit3 className="icon-action" onClick={() => handleEditarClick(palpite)} />
              <FiTrash2 className="icon-action" onClick={() => handleExcluirClick(palpite)} />
            </>
          )}
        </div>
      </ListGroup.Item>
    ))}
  </ListGroup>
  
  ) : (
    <Alert variant="warning">Nenhum palpite encontrado para esta data.</Alert>
  )}





<Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
    <h2>Editar Palpite</h2>
    <Form>
      <Form.Group className="mb-3">
        
        <Form.Label>{editablePalpite.team1Name} </Form.Label>
        <Form.Control type="number" value={editablePalpite.palpiteTeam1} onChange={(e) => setEditablePalpite({ ...editablePalpite, palpiteTeam1: e.target.value })} />
        
        <br/>
        <Form.Label> {editablePalpite.team2Name}</Form.Label>
        <Form.Control type="number" value={editablePalpite.palpiteTeam2} onChange={(e) => setEditablePalpite({ ...editablePalpite, palpiteTeam2: e.target.value })} />
      </Form.Group>
      <Button variant="secondary" onClick={() => setModalIsOpen(false)}>Cancelar</Button>
      <Button variant="primary" onClick={handleSalvarEdicao}>Salvar Alterações</Button>
    </Form>
</Modal>
    </Container>
  );
};

export default PalpitesDetalhesMobile;