import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, googleAuthProvider } from '../firebase/firebaseConfig';
import { signInWithRedirect, getRedirectResult, onAuthStateChanged } from 'firebase/auth';

import 'bootstrap/dist/css/bootstrap.min.css';
import './HomePage.css';
import useIsMobile from '../hooks/useIsMobile';
import MobileHomePage from './MobileHomePage';
import backgroundImage from '../assets/inicial.png';
import siteIcon from '../assets/siteicone.png'; 
import googleIcon from '../assets/google.svg'; 

const HomePage = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkRedirectResult = async () => {
      const result = await getRedirectResult(auth);
      if (result) {
        // Redireciona para /post-login-adm após o login bem-sucedido
        navigate('/post-login-adm');
      }
    };

    checkRedirectResult();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(false);
      if (user) {
        navigate('/dashboard');
      }
    });

    return unsubscribe;
  }, [navigate]);

  const handleGoogleSignIn = () => {
    signInWithRedirect(auth, googleAuthProvider);
  };

  if (loading) {
    return <div className="loading">Carregando...</div>;
  }

  if (isMobile) {
    return <MobileHomePage />;
  }

  return (
    <div className="home-page">
      <div className="half-screen green-background" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover',paddingRight: '150px' }}>
        
      </div>
      <div className="half-screen">
        <header className="header-section">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <div className="navbar-brand" >
                <img src={siteIcon} alt="Ícone do Site" style={{ width: '100px', marginRight: '10px' }} />
                Palpitando
              </div>
            </div>
          </nav>
          <div className="header-content">
            <h4 className="header-title">Bem-vindo ao Palpitando!</h4>
            <p className="header-tagline">Participe e mostre que você é um craque nos palpites de futebol.</p>
          </div>
          <div className="login-section">
            <Link to="/login" className="btn btn-outline-success me-2">
            <img src={siteIcon} alt="Ícone do Site" style={{ width: '25px', marginRight: '10px'}} /> Entrar
              </Link>
            <Link to="/cadastro" className="btn btn-outline-success me-2">
            <img src={siteIcon} alt="Ícone do Site" style={{ width: '25px', marginRight: '10px' }} />
            Cadastrar
            </Link>
            <Link to="/cadastro-adm" className="btn btn-outline-success me-2">
            <img src={siteIcon} alt="Ícone do Site" style={{ width: '25px', marginRight: '10px' }} />
              Cadastro Admin
            </Link>
         
            <button onClick={handleGoogleSignIn}  className="btn btn-outline-success" style={{marginTop:'10px'}}>
              <img src={googleIcon} alt="Google" style={{ width: '20px', marginRight: '8px', verticalAlign: 'text-bottom' }} />
              Entrar com Google
            </button>
      
          </div>
        </header>
      </div>
    </div>
  );
};

export default HomePage;
