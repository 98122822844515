import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, onValue, get, set, push, update, child } from 'firebase/database';

import { format, addDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import './DashboardMobile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';


const DashboardMobile = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [matches, setMatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [message, setMessage] = useState('');
  
  
  const database = getDatabase();
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
  }, [auth]);

  useEffect(() => {
    // Definindo o caminho dos jogos
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
    const day = String(selectedDate.getDate()).padStart(2, '0');
    const matchesPath = `Matches/${year}/${month}/${day}`;
  
    // Definindo o caminho dos palpites enviados
    const predictionsPath = `Predictions/${year}/${month}/${day}/${currentUser?.uid}/jogos`;
  
    setIsLoading(true);
  
    // Carregando jogos
    const unsubscribeMatches = onValue(ref(database, matchesPath), (matchesSnapshot) => {
      const matchesData = [];
  
      matchesSnapshot.forEach((childSnapshot) => {
        const matchID = childSnapshot.key;
        const matchData = childSnapshot.val();
  
        // Inicialmente marcamos todos os jogos como não enviados
        matchesData.push({
          ...matchData,
          matchID,
          enviado: false, // Adicionando a propriedade predictionSent
        });
      });

        // Ordena os jogos pela hora mais recente
      matchesData.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
  
      // Carregando palpites enviados
      get(ref(database, predictionsPath)).then((predictionsSnapshot) => {
        if (predictionsSnapshot.exists()) {
          const predictionsData = predictionsSnapshot.val();
  
          // Marcando os jogos que têm palpites enviados
          matchesData.forEach((match) => {
            if (predictionsData[match.matchID]) {
              match.enviado = true; // Atualizando a propriedade com base nos palpites enviados
            }
          });
        }
  
        // Atualizando o estado dos jogos
        setMatches(matchesData);
        setIsLoading(false);
      });
    });
  
    return () => {
      unsubscribeMatches();
    };
  }, [selectedDate, currentUser]);
  
  
  
  
  
  

  
  


  const handleSetToday = () => {
    setSelectedDate(new Date());
  };

  const handleSetTomorrow = () => {
    setSelectedDate(addDays(new Date(), 1));
  };

  const handlePalpiteChange = (index, field, value) => {
    const updatedMatches = [...matches];
    updatedMatches[index][field] = value;
    setMatches(updatedMatches);
  };
  
  
  
  


const handleSubmit = async (index) => {
  const match = matches[index];

  if (!match.palpiteA || !match.palpiteB) {
    Swal.fire('Erro!', 'Você precisa digitar ambos os palpites antes de enviar.', 'error');
    return;
  }

  // Certifique-se de que o apelido do usuário está disponível
  const userApelidoPath = `usuarios/${currentUser.uid}/apelido`;
  const apelidoSnapshot = await get(ref(database, userApelidoPath));
  if (!apelidoSnapshot.exists()) {
    console.error("Erro ao buscar apelido do usuário.");
    return;
  }
  const userApelido = apelidoSnapshot.val();

  // Formatar a data para o caminho do Firebase
  const dateFormatted = format(selectedDate, 'yyyy/MM/dd');
  // Gerar um ID único para a predição
  const predictionId = `prediction-${Date.now()}`;

  // Construir o caminho incluindo userID, matchID e o predictionId
  const predictionPath = `Predictions/${dateFormatted}/${currentUser.uid}/jogos/${match.matchID}/${predictionId}`;

  // Verificar se já existe uma predição para esse jogo e usuário na data selecionada
  const existingPredictionRef = ref(database, `Predictions/${dateFormatted}/${currentUser.uid}/jogos/${match.matchID}`);
  const existingPredictionSnapshot = await get(existingPredictionRef);

  if (existingPredictionSnapshot.exists()) {
    Swal.fire('Atenção!', 'Você já fez um palpite para esta partida.', 'warning');
    return;
  }

  // Criar o objeto de predição
  const newPrediction = {
    userID: currentUser.uid,
    userApelido,
    matchID: match.matchID,
    palpiteTeam1: match.palpiteA,
    palpiteTeam2: match.palpiteB,
    pointsEarned: 0,
    gameDateTime: match.dateTime,
    team1Name: match.team1Name,
    team2Name: match.team2Name,
    status: 'Aguardando',
    PontoContabilizado: false,
    enviado: true
  };

  // Adicionar a nova predição ao Firebase
  await set(ref(database, predictionPath), newPrediction);
  Swal.fire('Sucesso!', 'Palpite enviado com sucesso!', 'success');

  // Atualizar a interface do usuário
  const updatedMatches = [...matches];
  updatedMatches[index].palpiteA = '';
  updatedMatches[index].palpiteB = '';
  updatedMatches[index].enviado = true; // Marca o jogo como tendo um palpite enviado
  setMatches(updatedMatches); 
};







  
  
  

  const buttonStyle = {
    padding: '4px 12px',
    fontSize: '14px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    margin: '0 5px',
  };

  return (
    <div style={{ padding: '10px', maxWidth: '100vw', overflowX: 'hidden' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
        <DatePicker
          selected={selectedDate}
          onChange={date => setSelectedDate(date)}
          locale={ptBR}
          dateFormat="dd/MM/yyyy"
       
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
        <button onClick={handleSetToday} style={buttonStyle}>Hoje</button>
        <button onClick={handleSetTomorrow} style={buttonStyle}>Amanhã</button>
      </div>
      <div style={{ padding: '10px 10px 60px 10px', fontSize: '12px', maxHeight: '100vh', overflow: 'auto' }}>
        {isLoading && <p>Carregando jogos...</p>}
        {!isLoading && matches.length === 0 && <p>{message}</p>}
        {matches.map((match, index) => (
  <div key={index} style={{
    marginBottom: '10px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    background: new Date(match.dateTime) <= new Date() ? '#d3d3d3' : (match.enviado ? '#f0f0f0' : (index % 2 === 0 ? '#61b89c' : '#1ced85')),
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    color: new Date(match.dateTime) <= new Date() ? 'white' : 'black',
  }}>
    <div><strong>Jogo:</strong> {match.team1Name} vs {match.team2Name}</div>
    <div><strong>Data e Hora:</strong> {format(new Date(match.dateTime), 'dd/MM/yyyy HH:mm')}</div>
    <div><strong>Criado por:</strong> {match.createdByUserApelido}</div>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <input
        type="number"
        placeholder="Palpite para time 1"
        value={match.palpiteA}
        onChange={(e) => handlePalpiteChange(index, 'palpiteA', e.target.value)}
        style={{ width: '50px', marginRight: '10px' }}
        disabled={new Date(match.dateTime) <= new Date() || match.enviado}
      />
      <input
        type="number"
        placeholder="Palpite para time 2"
        value={match.palpiteB}
        onChange={(e) => handlePalpiteChange(index, 'palpiteB', e.target.value)}
        style={{ width: '50px', marginRight: '10px' }}
        disabled={new Date(match.dateTime) <= new Date() || match.enviado}
      />
      <button onClick={() => handleSubmit(index)} style={buttonStyle} disabled={new Date(match.dateTime) <= new Date() || match.enviado}>
        Enviar
      </button>
      {match.enviado && <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', fontSize: '24px', marginLeft: '10px' }} />}
    </div>
    {new Date(match.dateTime) <= new Date() && <p style={{ color: 'red' }}>O jogo já começou</p>}
  </div>
))}



      </div>
    </div>
  );
};

export default DashboardMobile;