import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth, database } from '../firebase/firebaseConfig';
import { ref, get, set } from 'firebase/database';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PostLoginModal.css';

const PostLoginModalAdm = () => {
  const [nickname, setNickname] = useState('');
  const [accountName, setAccountName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setAccountName(user.displayName || '');
      const userRef = ref(database, `usuarios/${user.uid}`);
      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {
          Swal.fire({
            title: 'Dados já registrados',
            text: 'Você já completou seu perfil. Redirecionando para o dashboard...',
            icon: 'info',
            timer: 3000,
            didOpen: () => {
              Swal.showLoading();
            }
          }).then(() => {
            navigate('/dashboard');
          });
        }
      }).catch(error => {
        console.error('Erro ao buscar usuário:', error);
        Swal.fire('Erro', 'Houve um problema ao buscar seus dados. Tente novamente.', 'error');
      });
    } else {
      navigate('/');
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;

    if (!user) return;

    if (!nickname.trim()) {
      Swal.fire('Apelido Obrigatório', 'Por favor, preencha o campo do apelido.', 'warning');
      return;
    }

    if (accountName.includes(' ')) {
      Swal.fire('Nome da Conta Inválido', 'O nome da conta não pode conter espaços.', 'error');
      return;
    }

    const usersRef = ref(database, 'usuarios');
    get(usersRef).then((snapshot) => {
      if (snapshot.exists()) {
        const users = snapshot.val();
        const accountNames = Object.values(users).map(user => user.nome);

        if (accountNames.includes(accountName)) {
          Swal.fire('Nome da Conta Indisponível', 'Este nome de conta já está em uso. Por favor, escolha outro.', 'error');
          return;
        }
      }

      const apelidoRef = ref(database, `apelidos/${nickname}`);
      get(apelidoRef).then((apelidoSnapshot) => {
        if (apelidoSnapshot.exists()) {
          Swal.fire('Apelido Indisponível', 'Este apelido já está em uso. Por favor, escolha outro.', 'error');
        } else {
          set(ref(database, `usuarios/${user.uid}`), {
            nome: accountName,
            apelido: nickname,
            email: user.email,
            isAdmin: true,
          });

          set(apelidoRef, user.uid);

          Swal.fire('Perfil Atualizado', 'Suas informações foram salvas com sucesso!', 'success').then(() => {
            navigate('/dashboard');
          });
        }
      }).catch(error => {
        console.error('Erro ao salvar apelido:', error);
        Swal.fire('Erro', 'Houve um problema ao salvar seu apelido. Tente novamente.', 'error');
      });
    }).catch(error => {
      console.error('Erro ao verificar nome da conta:', error);
      Swal.fire('Erro', 'Houve um problema ao verificar o nome da conta. Tente novamente.', 'error');
    });
  };

  return (
    <div className="post-login-container">
      <h1>Complete seu Perfil de Administrador</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Nome da Conta:</label>
          <input type="text" className="form-control" value={accountName} onChange={e => setAccountName(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Apelido:</label>
          <input type="text" className="form-control" value={nickname} onChange={e => setNickname(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Perfil:</label>
          <input type="text" className="form-control-plaintext" value="Administrador" readOnly />
        </div>
        <button type="submit" className="btn btn-primary">Salvar e Continuar</button>
        <Link to="/" className="btn btn-link">Voltar para a tela inicial</Link>
      </form>
    </div>
  );
};

export default PostLoginModalAdm;
