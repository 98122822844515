import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importação do Bootstrap

const CriarEmBreve = () => {
  const [progress, setProgress] = useState(0); // Iniciar a progressão em 0%

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + 1;
        if (nextProgress > 56) { // Parar a animação em 56%
          clearInterval(interval);
          return 98; // Definir a progressão para 56%
        }
        return nextProgress;
      });
    }, 20); // Ajuste a velocidade da animação conforme necessário

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container text-center mt-5">
      <h2>Em breve: o site de palpites de futebol estará pronto!</h2>
      <div className="progress my-3">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progress}%` }}
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {progress}%
        </div>
      </div>
      <p>{progress}% concluído</p>
    </div>
  );
};

export default CriarEmBreve;
