import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth, database } from '../firebase/firebaseConfig';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, set } from 'firebase/database';
import Swal from 'sweetalert2';
import './SignUp.css';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [apelido, setApelido] = useState('');
  const [senha, setSenha] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verificações e validações
    if (nome.includes(' ') || apelido.includes(' ')) {
      Swal.fire({
        icon: 'error',
        title: 'Nome da Conta ou Apelido Inválido',
        text: 'O Nome da Conta e o Apelido não podem conter espaços.',
      });
      return;
    }

    if (!/^\d+$/.test(senha)) {
      Swal.fire({
        icon: 'error',
        title: 'Senha Inválida',
        text: 'A senha deve conter apenas números.',
      });
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, senha);
      const user = userCredential.user;

      await set(ref(database, `usuarios/${user.uid}`), {
        nome,
        email,
        apelido,
      });

      Swal.fire('Cadastro Realizado!', 'Seu cadastro foi realizado com sucesso!', 'success');
      navigate('/dashboard'); // Redireciona para o dashboard após o cadastro
    } catch (error) {
      let errorMessage = 'Erro ao realizar cadastro.';
      if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'O e-mail fornecido já está em uso.';
      }
      console.error('Erro ao criar usuário:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro no Cadastro',
        text: errorMessage,
      });
    }
  };

  return (
    <div className="signup-container">
      <Link to="/" className="back-to-login-link">Voltar para a tela inicial</Link>
      <h2 className="signup-title">Cadastro</h2>
      <form onSubmit={handleSubmit} className="signup-form-mobile">
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="nome">Nome Da Conta</label>
            <input
              id="nome"
              type="text"
              placeholder="Nome Da Conta"
              value={nome}
              onChange={e => setNome(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="apelido">Apelido</label>
          <input
            id="apelido"
            type="text"
            placeholder="Apelido"
            value={apelido}
            onChange={e => setApelido(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="senha">Senha (apenas números)</label>
          <input
            id="senha"
            type="password"
            placeholder="Senha"
            value={senha}
            onChange={e => setSenha(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="signup-button">Registrar</button>
      </form>
    </div>
  );
};

export default SignUp;
