import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, googleAuthProvider } from '../firebase/firebaseConfig';
import { signInWithPopup, onAuthStateChanged, signInWithRedirect, getRedirectResult } from 'firebase/auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MobileHomePage.css';
import googleIcon from '../assets/google.svg';
import siteIcon from '../assets/siteicone.png';

const MobileHomePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(false);
      if (user) {
        navigate('/post-login-adm');
      }
    });

    // Adiciona a verificação do resultado do redirecionamento aqui
    const checkRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          // Usuário está logado, navegar para a página de pós-login
          navigate('/post-login-adm');
        }
      } catch (error) {
        console.error("Erro após redirecionamento:", error);
      }
    };

    checkRedirectResult();

    return unsubscribe;
  }, [navigate]);

  const handleGoogleSignInAdm = async () => {
    try {
      await signInWithRedirect(auth, googleAuthProvider);
    } catch (error) {
      console.error("Erro na autenticação de Administrador com o Google:", error);
    }
  };

  if (loading) {
    return <div className="loading">Carregando...</div>;
  }

  return (
    <div className="container mt-5 d-flex justify-content-center">
      <div className="card p-4" style={{ borderRadius: '20px',marginLeft:'35px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
        <div className="card-body text-center">
          <img src={siteIcon} alt="Site Icon" style={{ width: '100px', marginBottom: '10px' }} />
          <h2 className="mb-4">Bem-vindo ao Palpitando!</h2>
          <div className="mb-4">
            <Link to="/login" className="btn btn-primary me-2" >Login</Link>
            <Link to="/cadastro" className="btn btn-secondary me-2" style={{background:'#008000'}}>Cadastro</Link>
            <Link to="/cadastro-adm" className="btn btn-warning" style={{marginTop:'10px', borderRadius:'20px'}} >Cadastro Admin</Link>
          </div>
          <p>Participe e mostre que você é um craque nos palpites de futebol.</p>
          <button onClick={handleGoogleSignInAdm} className="btn btn-light mt-3 d-flex align-items-center justify-content-center" style={{ borderRadius: '20px', width: '100%' }}>
            <img src={googleIcon} alt="Google" style={{ width: '24px', marginRight: '8px' }} />
            Entrar com Google
          </button>
        </div>
      </div>
    </div>
  );  
};

export default MobileHomePage;
