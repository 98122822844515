import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { useAuth } from './contexts/AuthContext';
import { database } from './firebase/firebaseConfig';
import { ref, onValue } from 'firebase/database'; // Importar onValue
import siteIcon from './assets/siteicone.png';

const Navbar = () => {
  const { currentUser } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(true); 

  useEffect(() => {
    if (currentUser) {
      const userRef = ref(database, `usuarios/${currentUser.uid}`);

      // Subscrição para mudanças no status de admin do usuário
      const unsubscribe = onValue(userRef, (snapshot) => {
        if (snapshot.exists()) {
          setIsAdmin(!!snapshot.val().isAdmin); // Atualiza o estado isAdmin
        }
        setLoading(false);
      });

      // Função de limpeza para remover o listener
      return () => unsubscribe();
    } else {
      setLoading(false); // Define loading como false se não houver usuário
    }
  }, [currentUser]);

  if (!currentUser || loading || !isVisible) { // Verifica a visibilidade junto com as outras condições
    return null;
  }

  return (
    <nav className="navbar">
      <div className="navbar-logo">
      <img src={siteIcon} alt="Ícone do Site" style={{ width: '65px', marginRight: '10px' }} />
            
             Palpitando
              </div>
      <div className="navbar-links">
        <Link to="/" className="navbar-link">Home</Link>
        {isAdmin && <Link to="/adicionar" className="navbar-link">Adicionar</Link>}
        {isAdmin && <Link to="/adm-palpites" className="navbar-link">Adm Palpites</Link>}
        {isAdmin && <Link to="/login-temporario" className="navbar-link"> login-temporario</Link>}
        <Link to="/tabela-da-liga" className="navbar-link">Tabela</Link>
        <Link to="/meus-palpites" className="navbar-link">Meus Palpites</Link>
        <Link to="/profile" className="navbar-link">Perfil</Link>
      </div>
    </nav>
  );
};

export default Navbar;
