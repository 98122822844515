// src/components/layouts/WebLayout.js
import React from 'react';
import Navbar from '../Navbar'; // Ajuste o caminho conforme necessário
import './WebLayout.css'; // Certifique-se de criar e referenciar o arquivo CSS apropriado


const WebLayout = ({ children }) => {
  return (
    <div className="web-layout">
      <Navbar />
      <main className="web-content">
        {children}
      </main>
    </div>
  );
};

export default WebLayout;
