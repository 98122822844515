// src/components/layouts/MobileLayout.js
import React from 'react';
import MobileNavbar from '../MobileNavbar'; // Ajuste o caminho conforme necessário
import './MobileLayout.css'; // Certifique-se de criar e referenciar o arquivo CSS apropriado

const MobileLayout = ({ children }) => {
  return (
    <div className="mobile-layout">
      <main className="mobile-content">
        {children}
      </main>
      <MobileNavbar />
    </div>
  );
};

export default MobileLayout;
