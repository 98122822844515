import React, { useState, useEffect } from 'react';
import { push, ref, set, get } from 'firebase/database';
import Swal from 'sweetalert2'; // Importa o Swal

const MatchFormMobile = ({ database, currentUser }) => {
  const [team1Name, setTeam1Name] = useState('');
  const [team2Name, setTeam2Name] = useState('');
  const [date, setDate] = useState(''); // Alterado para string vazia como valor inicial
  const [time, setTime] = useState(''); // Alterado para string vazia como valor inicial
  const [userApelido, setUserApelido] = useState('');// Estado para armazenar o apelido do usuário

  useEffect(() => {
    // Buscar o apelido do usuário autenticado
    const fetchUserApelido = async () => {
      if (currentUser?.uid) {
        const userRef = ref(database, `usuarios/${currentUser.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists() && snapshot.val().apelido) {
          setUserApelido(snapshot.val().apelido); // Atualiza o estado com o apelido do usuário
        }
      }
    };

    fetchUserApelido();
  }, [currentUser, database]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!team1Name || !team2Name || !date || !time) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Todos os campos devem ser preenchidos.'
      });
        return;
    }

    const dateTime = new Date(`${date}T${time}`).toISOString();
    const year = new Date(dateTime).getFullYear();
    const month = String(new Date(dateTime).getMonth() + 1).padStart(2, '0'); // Assegura dois dígitos para o mês
    const day = String(new Date(dateTime).getDate()).padStart(2, '0'); // Extrai e formata o dia

    const newMatch = {
        team1Name,
        team2Name,
        dateTime,
        result1: 'pendente',
        result2:'pendente',
        createdByUserId: currentUser?.uid,
        createdByUserApelido: userApelido // Utilizando o apelido do usuário
    };

    try {
        const matchRef = ref(database, `Matches/${year}/${month}/${day}`);
        const newMatchRef = push(matchRef);
        await set(newMatchRef, newMatch);
        Swal.fire(
          'Sucesso!',
          'Partida adicionada com sucesso!',
          'success'
        );

        setTeam1Name('');
        setTeam2Name('');
        setDate('');
        setTime('');
    } catch (error) {
        console.error('Erro ao adicionar partida:', error);
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao adicionar a partida. Por favor, tente novamente.'
        });
    }
};
  
  
  
  



  return (
    <div className="container mt-3">
      <h2 className="text-center mb-3">Adicionar Nova Partida</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Time da casa"
            value={team1Name}
            onChange={(e) => setTeam1Name(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Time de fora"
            value={team2Name}
            onChange={(e) => setTeam2Name(e.target.value)}
          />
        </div>
        <div className="mb-3">
      <label htmlFor="matchDate" className="form-label">Data da Partida (DD/MM/AAAA):</label>
      <input
        id="matchDate"
        type="date"
        className="form-control"
        placeholder="Data da Partida (DD/MM/AAAA):"
        value={date}
        onChange={(e) => setDate(e.target.value)}
      />
    </div>
    <div className="mb-3">
      <label htmlFor="matchTime" className="form-label">Hora da Partida (HH:MM):</label>
      <input
        id="matchTime"
        type="time"
        className="form-control"
        value={time}
        onChange={(e) => setTime(e.target.value)}
      />
    </div>
        <div className="d-grid">
          <button type="submit" className="btn btn-primary">Adicionar Partida</button>
        </div>
      </form>
    </div>
  );
};

export default MatchFormMobile;
