// src/firebase/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDxI98VgxJ_fr207dMMVBfhnCIAxKC-w60",
  authDomain: "apps-5155e.firebaseapp.com",
  projectId: "apps-5155e",
  storageBucket: "apps-5155e.appspot.com",
  messagingSenderId: "119602912088",
  appId: "1:119602912088:web:e8e00db9f76eb2951b232d",
  measurementId: "G-KXQ7LLZF43"
};


const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleAuthProvider = new GoogleAuthProvider(); // Inicializa e exporta o GoogleAuthProvider
export const database = getDatabase(app);

