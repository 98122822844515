// Arquivo: ProfileMobile.jsx
import React from 'react';
import './ProfileMobile.css';

const ProfileMobile = ({
  userData,
  handleEdit,
  handleChange,
  handleSave,
  editing,
  editedUserData,
  handleLogout,
  handleToggleAdmin,
  handleCancelEdit
}) => (
  <div className="profile-mobile">
    {userData && (
      <div className="user-details">
        <div className="fields-group">
          <div className="user-field">
            <strong>Nome:</strong>
            {editing ? (
              <input type="text" name="nome" value={editedUserData.nome || ''} onChange={handleChange} />
            ) : (
              <span>{userData.nome}</span>
            )}
          </div>

          <div className="user-field">
            <strong>Apelido:</strong>
            
              <span>{userData.apelido}</span>
           
          </div>
        </div>

        <div className="fields-group">
          <div className="user-field">
            <strong>Email:</strong>
            <span>{userData.email}</span>
          </div>

          <div className="user-field">
            <strong>É Admin:</strong>
            {editing ? (
              <select name="isAdmin" value={editedUserData.isAdmin ? 'true' : 'false'} onChange={handleChange}>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            ) : (
              <span>{userData.isAdmin ? 'Sim' : 'Não'}</span>
            )}
          </div>
        </div>

        <div className="action-buttons">
          {editing ? (
            <>
              <button onClick={handleSave} className="btn-save">Salvar</button>
              <button onClick={handleCancelEdit} className="btn-cancel">Cancelar</button>
            </>
          ) : (
            <button onClick={handleEdit} className="btn-edit">Editar</button>
          )}
          <button onClick={handleLogout} className="btn-logout">Sair</button>
          {!editing && userData.isAdmin && (
            <button onClick={() => handleToggleAdmin(false)} className="btn-remove-admin">Rem. Admin</button>
          )}
          {!editing && !userData.isAdmin && userData.isRequestedAdmin && (
            <button onClick={() => handleToggleAdmin(true)} className="btn-make-admin">Tornar Admin</button>
          )}
        </div>
      </div>
    )}
  </div>
);

export default ProfileMobile;
