import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../firebase/firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Swal from 'sweetalert2';
import './Login.css'; // Certifique-se de que o caminho para o arquivo CSS está correto
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, senha);
      navigate('/dashboard');
    } catch (error) {
      console.error("Erro ao fazer login:", error.message);
      Swal.fire({
        icon: 'error',
        title: 'Erro no Login',
        text: 'Falha ao realizar login. Verifique suas credenciais.',
      });
    }
  };

  return (
    <div className="login-container">
      <h2 className="login-title">Login</h2>
      <form onSubmit={handleSubmit} className="login-form">
        <div className="form-group">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group password-group">
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Senha"
            value={senha}
            onChange={e => setSenha(e.target.value)}
            required
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            onClick={() => setShowPassword(!showPassword)}
            className="password-icon"
          />
        </div>
        <button type="submit" className="login-button">Entrar</button>
      </form>
      <div className="login-links">
        <Link to="/password-reset" className="login-link">Esqueci minha senha</Link>
        <Link to="/cadastro" className="login-link">Ainda não tem conta? Cadastre-se</Link>
        <Link to="/" className="back-to-login-link">Voltar para a tela inicio</Link>
      </div>
    </div>
  );
};

export default Login;
