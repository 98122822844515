import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { database } from '../firebase/firebaseConfig';
import { ref, onValue, update,get,remove } from 'firebase/database';
import { format } from 'date-fns';

import ptBR from 'date-fns/locale/pt-BR'; // Importa o locale pt-BR
import DatePicker, { registerLocale } from 'react-datepicker';
import { Button, ListGroup, Modal, Form, Alert } from 'react-bootstrap';

import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import { Dropdown } from 'react-bootstrap';
import { FiTrash2, FiEdit3, FiCheckSquare, FiMoreVertical } from 'react-icons/fi';
import './AdminPalpitesMobile.css';


registerLocale('pt-BR', ptBR); // Registra o locale pt-BR

function AdminPalpitesMobile() {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    const [matches, setMatches] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [editMatchModalOpen, setEditMatchModalOpen] = useState(false);
    const [editMatch, setEditMatch] = useState({});
    const [resultModalOpen, setResultModalOpen] = useState(false);
    const [editResult, setEditResult] = useState({});

    useEffect(() => {
        const fetchData = async () => {
          const dateFormatted = format(selectedDate, 'yyyy/MM/dd');
          const matchesRef = ref(database, `Matches/${dateFormatted}`);
      
          onValue(matchesRef, (snapshot) => {
            const matchesData = [];
            snapshot.forEach(matchSnapshot => {
              const key = matchSnapshot.key;
              const data = matchSnapshot.val();
      
              // Verifica se o jogo pertence ao usuário logado
              if (data.createdByUserId === currentUser.uid) {
                matchesData.push({ id: key, ...data });
              }
            });
            setMatches(matchesData);

             // Ordena os jogos pela hora de início mais recente
        matchesData.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
        
          });
        };
      
        fetchData();
      }, [selectedDate, currentUser.uid]); // Adicionado currentUser.uid nas dependências
      

    const openEditMatchModal = (match) => {
        setEditMatch(match);
        setEditMatchModalOpen(true);
    };

    const closeEditMatchModal = () => {
        setEditMatchModalOpen(false);
    };

    const handleEditMatchChange = (e) => {
        const { name, value } = e.target;
        setEditMatch({ ...editMatch, [name]: value });
    };

     // Função para fechar o modal de edição de resultados
     const closeResultModal = () => {
        setResultModalOpen(false);
    };

    // Função para abrir o modal de edição de resultados
    const openResultModal = (match) => {
        setEditResult(match); // Supondo que você tenha um estado para armazenar o jogo a ser editado
        setResultModalOpen(true);
    };

    const saveMatchResult = async () => {
        try {
            const dateFormatted = format(selectedDate, 'yyyy/MM/dd');
    
            // Atualiza o resultado do jogo no nó Matches
            const matchRef = ref(database, `Matches/${dateFormatted}/${editResult.id}`);
            await update(matchRef, {
                result1: editResult.result1,
                result2: editResult.result2,
            });
    
            // Referência para o nó Predictions da data selecionada
            const predictionsRef = ref(database, `Predictions/${dateFormatted}`);
            onValue(predictionsRef, async (snapshot) => {
                if (snapshot.exists()) {
                    const predictionsData = snapshot.val();
    
                    // Iterando sobre as predições de cada usuário
                    for (const userId in predictionsData) {
                        const userPredictions = predictionsData[userId]?.jogos?.[editResult.id];
    
                        // Verifica se existe uma predição para o jogo específico
                        for (const predictionId in userPredictions) {
                            const userPrediction = userPredictions[predictionId];
    
                            // Compara a predição do usuário com os resultados reais e determina o status
                            const status = (userPrediction.palpiteTeam1 == editResult.result1 && userPrediction.palpiteTeam2 == editResult.result2) ? "Acertou" : "Errou";
                            const pointsEarned = status === "Acertou" ? 3 : 0;
    
                            // Atualiza a predição específica do usuário com os pontos e status
                            const predictionRef = ref(database, `Predictions/${dateFormatted}/${userId}/jogos/${editResult.id}/${predictionId}`);
                            await update(predictionRef, { status, pointsEarned });
                        }
                    }
                    Swal.fire('Sucesso!', 'Resultado do jogo e palpites dos usuários atualizados.', 'success');
                }
            }, { onlyOnce: true });
    
        } catch (error) {
            console.error("Erro ao salvar o resultado do jogo e atualizar palpites:", error);
            Swal.fire('Erro!', 'Ocorreu um problema ao atualizar os resultados e palpites.', 'error');
        }
    };
    
    const deleteMatch = async (match) => {
      // Extrai a data do jogo a partir do campo 'dateTime'
      const matchDate = new Date(match.dateTime);
      const currentDate = new Date();
    
      // Verifica se o jogo já começou comparando com a data e hora atuais
      if (matchDate <= currentDate) {
        Swal.fire(
          'Ação não permitida!',
          'Você não pode excluir um jogo que já começou ou terminou.',
          'error'
        );
        return;
      }
    
      // Solicita confirmação do usuário para a exclusão
      const result = await Swal.fire({
        title: 'Tem certeza?',
        text: "Você não poderá reverter isso!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar'
      });
    
      if (result.isConfirmed) {
        // Formata a data do jogo para corresponder à estrutura do banco de dados
        const dateFormatted = format(matchDate, 'yyyy/MM/dd');
        // Cria a referência para o jogo específico baseado na data e ID único
        const matchRef = ref(database, `Matches/${dateFormatted}/${match.id}`);
    
        try {
          // Tenta excluir o jogo
          await remove(matchRef);
          // Atualiza o estado para refletir a exclusão na UI
          setMatches(matches.filter(m => m.id !== match.id));
          Swal.fire('Excluído!', 'O jogo foi excluído com sucesso.', 'success');
        } catch (error) {
          console.error("Erro ao excluir o jogo:", error);
          Swal.fire('Erro!', 'Não foi possível excluir o jogo.', 'error');
        }
      }
    };
    
    
    
    
    
    const saveEditedMatch = async () => {
      if (!editMatch.id) {
        Swal.fire('Erro!', 'Jogo não identificado para edição.', 'error');
        return;
      }

      // Verifica se os nomes dos times estão preenchidos
    if (!editMatch.team1Name.trim() || !editMatch.team2Name.trim()) {
      Swal.fire('Erro!', 'Os nomes dos times não podem estar vazios.', 'error');
      return;
  }

    
      const dateFormatted = format(selectedDate, 'yyyy/MM/dd');
      const matchRef = ref(database, `Matches/${dateFormatted}/${editMatch.id}`);
    
      try {
        await update(matchRef, {
          team1Name: editMatch.team1Name,
          team2Name: editMatch.team2Name,
          dateTime: editMatch.dateTime,
        });
    
        // Atualiza a lista de jogos na interface
        const updatedMatches = matches.map(match => match.id === editMatch.id ? { ...match, ...editMatch } : match);
        setMatches(updatedMatches);
    
        Swal.fire('Sucesso!', 'Jogo atualizado com sucesso.', 'success');
        closeEditMatchModal(); // Fecha o modal após a atualização
      } catch (error) {
        console.error("Erro ao salvar as alterações do jogo:", error);
        Swal.fire('Erro!', 'Não foi possível salvar as alterações.', 'error');
      }
    };
    
    // Certifique-se de que saveEditedMatch está sendo chamada ao salvar as alterações, não saveMatchResult
    
       
    
    
    
    

    return (
        <div className="list-group-container">
             <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="dd/MM/yyyy"
                className="form-control mb-3"
                locale="pt-BR" // Aplica o locale pt-BR ao DatePicker
            />
            <div className="list-group-scroll">
            <ListGroup>
  {matches.map((match) => (
    <ListGroup.Item key={match.id} className="d-flex justify-content-between align-items-center" style={{ background: '#adffd1' }}>
      <div>
        {/* Exibe a data e a hora do jogo */}
        <small>{format(new Date(match.dateTime), 'dd/MM/yyyy HH:mm', { locale: ptBR })}</small>
        <br />
       
        {match.team1Name} {match.result1 && match.result2 ? ` ${match.result1} - ${match.result2}` : 'Resultado: Pendente'} {match.team2Name}
      </div>
      <div>
      <Dropdown>
  <Dropdown.Toggle variant="success" id="dropdown-basic" bsPrefix="p-0" style={{ background: 'none', border: 'none' }}>
  <FiMoreVertical style={{ color: 'black' }} />
  </Dropdown.Toggle>

  <Dropdown.Menu style={{ backgroundColor: '#004085', color: 'white' }}>
    <Dropdown.Item style={{ color: 'white' }} onClick={() => openEditMatchModal(match)}>
      <FiEdit3 className="me-2" /> Editar
    </Dropdown.Item>
    <Dropdown.Item style={{ color: 'white' }} onClick={() => deleteMatch(match)}>
  <FiTrash2 className="me-2" /> Excluir
</Dropdown.Item>

    {/* Terceira opção adicionada aqui */}
    <Dropdown.Item style={{ color: 'white' }} onClick={() => openResultModal(match)}>
      <FiCheckSquare className="me-2" /> Resultado
    </Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
      </div>
    </ListGroup.Item>
  ))}
</ListGroup>
</div>

            {matches.length === 0 && <Alert variant="info">Nenhum jogo encontrado para esta data.</Alert>}

            {/* Modal para editar jogo */}
            <Modal show={editMatchModalOpen} onHide={closeEditMatchModal}>
  <Modal.Header closeButton>
    <Modal.Title>Editar Jogo</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>FORA</Form.Label>
        <Form.Control type="text" name="team1Name" value={editMatch.team1Name || ''} onChange={handleEditMatchChange} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>CASA</Form.Label>
        <Form.Control type="text" name="team2Name" value={editMatch.team2Name || ''} onChange={handleEditMatchChange} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Data e Hora</Form.Label>
        <Form.Control type="datetime-local" name="dateTime" value={editMatch.dateTime || ''} onChange={handleEditMatchChange} />
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={closeEditMatchModal}>Fechar</Button>
    <Button variant="primary" onClick={saveEditedMatch}>Salvar Alterações</Button>
  </Modal.Footer>
</Modal>


            {/* Modal para inserir resultados reais */}
            <Modal show={resultModalOpen} onHide={closeResultModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Inserir Resultados Reais</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Resultado do {editResult.team1Name}</Form.Label>
                            <Form.Control type="number" name="result1" value={editResult.result1 || ''} onChange={(e) => setEditResult({ ...editResult, result1: e.target.value })} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Resultado do {editResult.team2Name}</Form.Label>
                            <Form.Control type="number" name="result2" value={editResult.result2 || ''} onChange={(e) => setEditResult({ ...editResult, result2: e.target.value })} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeResultModal}>Fechar</Button>
                    <Button variant="primary" onClick={saveMatchResult}>Salvar Resultados</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AdminPalpitesMobile;

